import axios from "axios";
export function getRequestParams(methodType, request) {
    if (methodType === "GET") {
        const requestParams = {
            method: methodType,
            headers: {
                'Content-Type': 'application/json',
                'CultureCode': sessionStorage.getItem('session_culturecode'),
                'Authorization': 'Bearer ' + sessionStorage.getItem('session_token')
            },
        }
        return requestParams;
    }
    else if (methodType === "DELETE") {
        const requestParams = {
            method: methodType,
            headers: {
                'Content-Type': 'application/json',
                'CultureCode': sessionStorage.getItem('session_culturecode'),
                'Authorization': 'Bearer ' + sessionStorage.getItem('session_token')
            },
        }
        return requestParams;
    }
    else if (methodType === "PUT" && request !== null) {
        const requestParams = {
            method: methodType,
            headers: {
                'Content-Type': 'application/json',
                'CultureCode': sessionStorage.getItem('session_culturecode'),
                'Authorization': 'Bearer ' + sessionStorage.getItem('session_token')
            },
            body: JSON.stringify(request)
        }
        return requestParams;
    }
    else if (methodType === "PUT" && request === null) {
        const requestParams = {
            method: methodType,
            headers: {
                'Content-Type': 'application/json',
                'CultureCode': sessionStorage.getItem('session_culturecode'),
                'Authorization': 'Bearer ' + sessionStorage.getItem('session_token')
            },
        }
        return requestParams;
    } else {
        const requestParams = {
            method: methodType,
            headers: {
                'Content-Type': 'application/json',
                'CultureCode': sessionStorage.getItem('session_culturecode'),
                'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                'token': '7843747347374374'
            },
            body: JSON.stringify(request)
        }
        return requestParams;
    }
}

export function formatFullDate(date, dateformat) {
    if (dateformat == "MMM/dd/yyyy") {
        var d = new Date(date),
            month = '' + (d.getMonth()),
            day = '' + d.getDate(),
            year = d.getFullYear();
        date = d.getDay();

        const newdate = new Date(year, month, day);  // 2009-11-10
        const monthName = newdate.toLocaleString('default', { month: 'long' });
        const dayName = newdate.toLocaleString("en-US", { "weekday": "long" })

        return [dayName, monthName, day, year].join(' ');
    }

}
export function formatDate(date, dateformat) {
    if (dateformat == "MM/dd/yyyy") {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
    }

    if (dateformat == "MMM/dd/yyyy") {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        const newdate = new Date(year, month, day);  // 2009-11-10
        const monthName = newdate.toLocaleString('default', { month: 'short' });

        return [day, monthName, year].join(' ');
    }



    if (dateformat == "MM/dd/yyyy hh:mm") {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        var hour = '' + d.getHours();
        var minutes = '' + d.getMinutes();

        if (hour <= 9) {
            hour = '0' + hour;
        }

        if (minutes <= 9) {
            minutes = '0' + minutes;
        }

        return [month, day, year].join('/') + " " + [hour, minutes].join(':');
    }

    if (dateformat == "MM/dd/yyyy hh:mm:ss") {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        var hour = '' + d.getHours();
        var minutes = '' + d.getMinutes();
        var seconds = '' + d.getSeconds();

        if (hour <= 9) {
            hour = '0' + hour;
        }

        if (minutes <= 9) {
            minutes = '0' + minutes;
        }

        if (seconds <= 9) {
            seconds = '0' + seconds;
        }

        return [month, day, year].join('/') + " " + [hour, minutes, seconds].join(':');
    }

    return [month, day, year].join('/');
}

export function formatTime(date, timeformat) {
    if (timeformat == "hh:mm") {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        var hour = '' + d.getHours();
        var minutes = '' + d.getMinutes();

        if (hour <= 9) {
            hour = '0' + hour;
        }

        if (minutes <= 9) {
            minutes = '0' + minutes;
        }

        return [hour, minutes].join(':');
    }

    if (timeformat == "hh:mm:ss") {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        var hour = '' + d.getHours();
        var minutes = '' + d.getMinutes();
        var seconds = '' + d.getSeconds();

        if (hour <= 9) {
            hour = '0' + hour;
        }

        if (minutes <= 9) {
            minutes = '0' + minutes;
        }

        if (seconds <= 9) {
            seconds = '0' + seconds;
        }

        return [hour, minutes, seconds].join(':');
    }

    return [hour, minutes, seconds].join(':');
}

export function formatFromDate(date, dateformat) {
    if (dateformat == "MM/dd/yyyy hh:mm") {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        var hour = "03";
        var minutes = "01";

        return [month, day, year].join('/') + " " + [hour, minutes].join(':');
    }

    return [month, day, year].join('/');
}

export function formatToDate(date, dateformat) {
    if (dateformat == "MM/dd/yyyy hh:mm") {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        var hour = "02";
        var minutes = "59";
        return [month, day, year].join('/') + " " + [hour, minutes].join(':');
    }

    return [month, day, year].join('/');
}

export function formatSearchDate(date1, date2, dateformat) {
    var finaldate = date2;
    try {
        var Difference_In_Time = new Date(date2).getTime() - new Date(date1).getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        if (Difference_In_Days == 0) {
            let d = new Date(date2);
            let searchDate = new Date(d.getFullYear(), d.getMonth(), d.getDate());
            finaldate = new Date(new Date(searchDate).setDate(searchDate.getDate() + 1));
        }
        else {
            return formatToDate(date2, dateformat);
        }

        if (dateformat == "MM/dd/yyyy hh:mm") {
            var d = new Date(finaldate),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            var hour = "02";
            var minutes = "59";
            return [month, day, year].join('/') + " " + [hour, minutes].join(':');
        }

    } catch (e) {
        //debugger;
    }
    return [month, day, year].join('/');
}

export async function getCurrentLocation() {
    let lat = 0,
        lng = 0;
    try {
        if (navigator.geolocation) {
            let result = await navigator.permissions.query({ name: "geolocation" });
            if (result.state === "granted" || result.state === "prompt") {
                const coords = await getCoords();
                lat = coords.lat;
                lng = coords.lng;
            }
            else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
            }
        }
        if (lat === 0 && lng  === 0) {
            const curlocation = await axios.get("https://ipapi.co/json");
            if (curlocation?.data) {
                lat = curlocation.data.latitude;
                lng = curlocation.data.longitude;
            }
        }
    } catch (error) {
        console.log("Error in handleOnClearMarkerClick function", error);
    }
    return { lat, lng }
}
const getCoords = async () => {
    const pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    return {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
    };
};

