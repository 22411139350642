import React, { Component, Fragment } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import moment from 'moment';
import { BasicMapStyle } from '../../helpers/mapstylejson';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'; //npm install --save sweetalert2
import { getRequestParams} from '../../helpers/globalfunctions.js';
const DISPLAY_DATETIME_FORMAT = "DD/MM/YYYY HH:mm";
const API_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
const DISPLAY_FORMAT = "YYYY/MM/DD HH:mm"

let currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export class ReplayComponent extends Component {
    static displayName = ReplayComponent.name;

    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") == "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        let startTime = moment(moment(currentTime).subtract(60, 'minute').format(DISPLAY_DATETIME_FORMAT), DISPLAY_DATETIME_FORMAT).toDate();
        let endTime = moment(moment(currentTime).endOf('day').format(DISPLAY_DATETIME_FORMAT), DISPLAY_DATETIME_FORMAT).toDate();
        super(props);
        this.mapRef = React.createRef();
        let rideId = 0;
        let vehicleId = 0;
        let fromDatetime = "";
        let toDatetime = "";
        if (this.props.location.search) {
            const queryString = require('query-string');
            var parsed = queryString.parse(this.props.location.search);
            if (parsed) {
                vehicleId = (!parsed) ? 0 : Number(parsed.vehicleId);
                fromDatetime = (!parsed) ? startTime : parsed.fromDatetime;
                toDatetime = (!parsed) ? endTime : parsed.toDatetime;
                rideId = (!parsed) ? 0 : Number(parsed.rideId);
            }
        }
        this.state = {
            isOpenModal: false,
            loadPanelVisible: false,
            isSubmited: false,
            showPoiOnMap: false,
            locationList: [],
            locationId: 0,
            vehicleNoList: [],
            groupedVehiclesDataSource: null,
            geofence: [],
            tripId: 0,
            rideId: rideId,
            vehicleId: vehicleId,
            sourceUrl: "",
            sourceState: "",
            vehicleReplayModal: false,
            fromDatetime: fromDatetime,
            toDatetime: toDatetime,
            overwriteDates: true,
            tripJobNumber: "",
            vehicleNo: "",
            tripStartDatetime: "",
            tripStartLocation: "",
            tripEndDatetime: "",
            tripEndLocation: "",
            tripTripStatusName: "",
            tripRouteName: "",
            serviceTypeId: 0,
            previewTypeId: "0",
            center: { lat: 19.086689, lng: 72.879449 },
            zoom: 10,
            trackingObj: null,
            progress: [],
            isPlay: false,
            isStopped: true,
            isPaused: true,
            overspeedChecked: false,
            replayCounter: 0,
            replaySpeed: 1000,
            displayPlayReplay: 0,
            speedwiseColorCodes: [],
            mapStyle: BasicMapStyle,
            showEventLabel: true,
            errors: {
                locationId: "",
                vehicleId: "",
                fromDatetime: "",
                toDatetime: "",
            },
            // isTrainServiceAccount: parseBoolean(secureLocalStorage.getItem('session_isTrainServiceAccount'))
        }
        this.map = null;
        this.replayMarkers = [];
        this.startEndMarkers = [];
        this.lastAnimatedMarker = null;
        this.replayTimer = null;
        this.replayRoutePolyLine = [];
        this.handleLocationInputChange = this.handleLocationInputChange.bind(this);
        this.handleVehicleInputChange = this.handleVehicleInputChange.bind(this);
        this.handleFromDatetimeChange = this.handleFromDatetimeChange.bind(this);
        this.handleToDatetimeChange = this.handleToDatetimeChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.playReplay = this.playReplay.bind(this);
        this.pauseReplay = this.pauseReplay.bind(this);
        this.stopReplay = this.stopReplay.bind(this);
        this.statusWebSocketInterval = null;
        this.fnCloseOtherWindows = this.fnCloseOtherWindows.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.exportButton = this.exportButton.bind(this);
        this.poiMarkers = [];
        this.poiCircles = [];
        this.poiPolygons = [];
    }

    fnCloseOtherWindows = async () => {
        this.setState({ filterWindow: "none" });
    }

    toggleSidebar = (e) => {
        e.preventDefault();
    }

    componentDidMount() {
        sessionStorage.setItem("session_PageName", "Replay");
        let mapStyle = BasicMapStyle;

        let accountId = parseInt(sessionStorage.getItem('session_accountId'));
        this.setState({ mapStyle: BasicMapStyle })
        mapStyle = BasicMapStyle;
        if ((this.state.serviceTypeId == 10 || this.state.serviceTypeId == 11) && this.state.vehicleReplayModal) {
            this.setState({
                displayPlayReplay: 1,
                previewTypeId: "1"
            });
        }

        this.map = new window.google.maps.Map(this.mapRef.current, {
            center: this.state.center,
            zoom: this.state.zoom,
            styles: mapStyle

        });

        if (this.state.vehicleReplayModal === undefined || this.state.vehicleReplayModal === true) {
            if (this.state.overwriteDates === true) {
                let fromDate = new Date(moment(currentTime).subtract(60, 'minute').format("MM/DD/YYYY HH:mm"));
                let toDate = new Date(moment(currentTime).format("MM/DD/YYYY") + " 23:59");
                this.setState({ fromDatetime: fromDate, toDatetime: toDate });
            }
            setTimeout(() => this.handleSearch(), 500);
        }
        setTimeout(() => this.handleSearch(), 500);
    }

    componentWillUnmount() {
        if (this.statusWebSocketInterval != null) {
            clearTimeout(this.statusWebSocketInterval);
        }

        this.setState = (state, callback) => {
            return;
        };
    }

    manageToggle = (e) => {
        if (document.getElementsByTagName("body")[0].className === "") {
            document.getElementsByTagName("body")[0].classList.add("sidebar-mini");
        }
    }

    handleLocationInputChange(data) {

    }

    handleSpeedChange = async (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ replaySpeed: parseInt(value) });
        this.pauseReplay(event);
        this.playReplay(event);
    }

    handleVehicleInputChange(data) {
        this.setState({ vehicleId: (data.selectedItem != null ? data.selectedItem.vehicleId : 0), vehicleNo: (data.selectedItem != null ? data.selectedItem.vehicleNo : ''), tripId: 0 })
    }

    handleFromDatetimeChange(date) {
        this.setState({ fromDatetime: date.value });
        this.state.errors["fromDatetime"] = "";
    }

    handleToDatetimeChange(date) {
        this.setState({ toDatetime: date.value });
        this.state.errors["toDatetime"] = "";
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (value === "1") {

        } else {
            window.clearInterval(this.replayTimer);
        }
    }

    checkChanged = async (e) => {
        this.setState({ overspeedChecked: e.currentTarget.checked });
    }

    checkEventChanged = async (e) => {
        this.setState({ showEventLabel: e.currentTarget.checked });
    }

    handleValidation() {
        let formIsValid = true;
        return formIsValid;
    }

    //#region SHOW POI

    getGeofence = async () => {
        try {
            let accountId = sessionStorage.getItem('session_accountId');
            let locationId = sessionStorage.getItem('session_locationId');
            this.setState({ loadPanelVisible: true });
            const requestParams = getRequestParams('GET');
            let response;
            if (this.state.tripId != 0) {
                response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'Geofences/' + accountId + "/" + locationId + "/" + this.state.tripId, requestParams);
            } else {
                response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'Geofences/' + accountId + "/" + locationId, requestParams);
            }
            let data = await response.json();
            if (data != null && data.payload != null && data.payload.length > 0) {
                let geofences = data.payload.filter((m) => m.points !== null && m.points !== undefined && m.points.length > 0);
                this.setState({ showPoiOnMap: true, geofence: geofences }, () => {
                    this.drawPOIMarkers();
                });
            }
        } catch (ex) {
            console.log("Error in " + this.displayName + ".getGeofence function", ex);
        }
    }

    drawPOIMarkers = () => {
        let poiMarker = null;
        //let bounds = new window.google.maps.LatLngBounds();
        this.state.geofence.map((poi) => {
            let point = poi.points[0];
            let markerLatlong = new window.google.maps.LatLng(point.latitude, point.longitude);
            let polygonBounds = new window.google.maps.LatLngBounds();
            if (poi.shapeTypeId == 1) {
                let poiCircle = new window.google.maps.Circle({
                    strokeColor: poi.polyBorderColor,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: poi.polyBgColor,
                    fillOpacity: 0.35,
                    center: markerLatlong,
                    radius: point.radius,
                    map: this.map,
                    poiId: poi.id,
                });
                this.poiCircles.push(poiCircle);
                //shape = poiCircle;
            }
            else {
                let Coordinates = [];
                for (var i = 0; i < poi.points.length; i++) {
                    Coordinates.push({
                        lat: Number(poi.points[i].latitude),
                        lng: Number(poi.points[i].longitude),
                    });

                    polygonBounds.extend(Coordinates[i]);
                }
                let polygon = new window.google.maps.Polygon({
                    paths: Coordinates,
                    strokeOpacity: 1.0,
                    geodesic: true,
                    strokeColor: poi.polyBorderColor,
                    editable: false,
                    fillColor: poi.polyBgColor,
                    fillOpacity: 0.3,
                    poiId: poi.id,
                    editable: false,
                });
                this.poiPolygons.push(polygon);
                polygon.setMap(this.map);
            }
            let latLng = poi.shapeTypeId == 1 ? markerLatlong : polygonBounds.getCenter();
            poiMarker = null;
            if (this.state.tripId !== 0) {
                var MarkerWithLabel = require('markerwithlabel')(window.google.maps);
                let markerbackgroundColor = "#eaf5fd";
                let markercolor = "#095590";
                poiMarker = new MarkerWithLabel({
                    position: latLng,
                    map: this.map,
                    //icon: iconImage,
                    //showInfoWindow: this.showInfo,
                    labelContent: poi.name,
                    labelStyle: {
                        backgroundColor: markerbackgroundColor,
                        border: "1px solid #cecece",
                        borderRadius: "8px",
                        padding: "2px 10px 2px 10px",
                        fontSize: "11px",
                        fontWeight: "bold",
                        fontFamily: 'Segoe UI',
                        color: markercolor,
                    },
                    labelAnchor: new window.google.maps.Point(20, 58),
                    text: poi.name,
                    title: poi.name,
                });
            } else {
                poiMarker = new window.google.maps.Marker({
                    poiId: poi.id,
                    position: latLng,
                    poiName: poi.name,
                    map: this.map,
                    draggable: false,
                    title: poi.name,
                });
            }

            this.poiMarkers.push(poiMarker);
        });
    }

    handleTogglePoisEvent = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ showPoiOnMap: value }, () => {
            if (this.state.geofence != null && this.state.geofence.length > 0) {
                this.handleTogglePois();
            } else {
                this.getGeofence();
            }
        })

    }

    handleTogglePois = () => {
        let _map = this.state.showPoiOnMap ? this.map : null;
        if (this.poiMarkers != null && this.poiMarkers.length > 0) {
            this.poiMarkers.map((marker) => {
                marker.setMap(_map);
            });
        }

        if (this.poiCircles != null && this.poiCircles.length > 0) {
            this.poiCircles.map((circle) => {
                circle.setMap(_map);
            });
        }

        if (this.poiPolygons != null && this.poiPolygons.length > 0) {
            this.poiPolygons.map((poly) => {
                poly.setMap(_map);
            });
        }
    }
    //#endregion SHOW POI
    handleSearch = async (e) => {
        this.setState({ showPoiOnMap: false, geofence: [] }, () => {
            this.handleTogglePois();
        });
        if (e !== undefined) {
            e.preventDefault();
        }
        var diff = moment(this.state.toDatetime).diff(moment(this.state.fromDatetime), 'hour');
        var hourDiff = this.state.serviceTypeId != 7 ? 48 : 240;
        if (diff > hourDiff) {
            window.clearInterval(this.replayTimer);
            this.setState({
                isSubmited: false,
                trackingObj: null,
            });
            this.statusWebSocketInterval = setTimeout(() => { this.loadReplayMarkers(); }, 3000);
            var msg = hourDiff == 48 ? 'Please select date range within 48 hours.' : 'Please select date range within 10 days.';
            toast.error(msg, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                style: { width: "320px" },
                hideProgressBar: false,
                newestOnTop: false,
                closeButton: true
            });
            return;
        }
        else {
            if (this.handleValidation()) {
                try {
                    this.setState({ progress: [], isPlay: false, isStopped: true, isPaused: true })
                    window.clearInterval(this.replayTimer);

                    if (this.state.previewTypeId == "1") {
                        this.setState({ displayPlayReplay: 1 })
                    }
                    var newObject = new Object();
                    newObject.VehicleId = this.state.vehicleId;
                    newObject.StartTime = moment(this.state.fromDatetime).format(API_DATETIME_FORMAT); //formatDate(this.state.fromDatetime,;
                    newObject.EndTime = moment(this.state.toDatetime).format(API_DATETIME_FORMAT);
                    this.setState({ isSubmited: true, });
                    const requestParams = getRequestParams('POST', newObject);
                    const response = await fetch('tracking-api/vehicleReplay', requestParams);
                    const data = await response.json();
                    if (this.state.tripId != 0) {
                        this.getGeofence();
                    }

                    this.setState({
                        isSubmited: false,
                        trackingObj: null
                    });
                   
                    if (data.payload == null || data.payload.replayData == null || data.payload.replayData.length === 0) {
                        const isValid = await Swal.fire({
                          //  title: "<small>Vehicle Replay</small>",
                            text: "Replay data not found.",
                            icon: "success",
                            width: '45%',
                            dangerMode: false,
                            allowOutsideClick: false
                        });
                    
                    } else {
                        this.setState({
                            trackingObj: data.payload,
                            speedwiseColorCodes: data.payload.speedwiseColorCodes
                        });
                        this.loadReplayMarkers();
                    }
                } catch (err) {
                    console.log("Error in " + this.displayName + ".handleSearch function", err);
                    window.clearInterval(this.replayTimer);
                    this.setState({
                        isSubmited: false,
                        trackingObj: null
                    });
                }
            }
        }
    }


    clearReplayMarker = () => {
        if (this.replayMarkers != null && this.replayMarkers.length > 0) {
            this.replayMarkers.map((marker) => {
                marker.setMap(null);
            });
            this.replayMarkers = [];
        }

        if (this.lastAnimatedMarker != null) {
            this.lastAnimatedMarker.setMap(null);
            this.lastAnimatedMarker = null;
        }
    }

    clearStartEndMarkers = function () {

        if (this.startEndMarkers != null) {
            this.startEndMarkers.map((marker) => {
                marker.setMap(null);
            });
            this.startEndMarkers = [];
        }

    }

    clearReplayPolyline = () => {
        if (this.replayRoutePolyLine != null && this.replayRoutePolyLine.length > 0) {
            this.replayRoutePolyLine.map((polymarker) => {
                polymarker.setMap(null);
            });
            this.replayRoutePolyLine = [];
        }
    }

    loadReplayMarkers = () => {

        this.clearReplayMarker();
        this.clearStartEndMarkers();
        this.clearReplayPolyline();

        if (this.state.trackingObj != null) {
            const bounds = new window.google.maps.LatLngBounds();
            let replayData = this.state.trackingObj.replayData;
            if (replayData != null) {
                bounds.extend(new window.google.maps.LatLng(replayData[0].latitude, replayData[0].longitude));
                for (var i = 1; i <= replayData.length - 2; i++) {
                    bounds.extend(new window.google.maps.LatLng(replayData[i].latitude, replayData[i].longitude));
                }
                bounds.extend(new window.google.maps.LatLng(replayData[replayData.length - 1].latitude, replayData[replayData.length - 1].longitude));

                this.map.fitBounds(bounds);
                this.createReplayMarker();

            }
        }
    }

    createReplayMarker = () => {

        this.state.trackingObj.replayData.map((replay, i) => {
            let replayNext;
            if (i <= this.state.trackingObj.replayData.length - 1) {
                replayNext = this.state.trackingObj.replayData[i + 1];
            }
            let movingstatus = "";
            if (i == 0) {
                movingstatus = "start";
                this.drawReplayMarker(replay, movingstatus);
            }
            else if (i == this.state.trackingObj.replayData.length - 1) {
                movingstatus = "end";
                this.drawReplayMarker(replay, movingstatus);
            }
            else {
                movingstatus = "position";
                if (this.state.previewTypeId == "1") {
                    if (replay.status === "Stopped" || replay.status === "Idle") {
                        //this.drawReplayMarker(replay, movingstatus);
                    }
                }
                else {
                    this.drawReplayMarker(replay, movingstatus);
                }
            }

            if (this.state.previewTypeId == "1" && replayNext != undefined) {
                this.drawRoutePolyline(replay.latitude, replay.longitude, replayNext.latitude, replayNext.longitude, "", replayNext.speed);
            }
        });
        //align overlapping markers.
        try {
            for (var i = 0; i < this.replayMarkers.length; i++) {
                var markerA = this.replayMarkers[i];
                for (var j = i + 1; j < this.replayMarkers.length; j++) {
                    var markerB = this.replayMarkers[j];
                    if (markerA != null && markerB != null) {
                        var dist = window.google.maps.geometry.spherical.computeDistanceBetween(markerA.getPosition(), markerB.getPosition());
                        if (dist < 5.0) {
                            var oldLatLng = markerB.getPosition();
                            var newLatLng = new window.google.maps.LatLng(oldLatLng.lat() + 0.00004, oldLatLng.lng());
                            markerB.setPosition(newLatLng);
                        }
                    }
                }
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".Align overlapping markers.", err);
        }

    }
    copyToClipboard = () => {
        console.log('copyToClipboard');
    }

    drawReplayMarker = (replayRowArr, movingstatus) => {
        try {
            var MarkerWithLabel = require('markerwithlabel')(window.google.maps);
            let latLng = { lat: parseFloat(replayRowArr.latitude), lng: parseFloat(replayRowArr.longitude) };
            let zIndex = 200, iconImage = "";

            if (movingstatus === "start") {
                iconImage = '../../dist/img/vehicle-replay/ico-map-start.png?v=1.0';
                zIndex = 201;
            }
            else if (movingstatus === "end") {
                zIndex = 200;
                iconImage = "../../dist/img/vehicle-replay/ico-map-stop.png?v=1.0";
                if (moment(moment().format("MM/DD/YYYY HH:mm")).diff(moment(replayRowArr.gpsDateTime), 'minute') < 15) {
                    iconImage = "../../dist/img/vehicle-replay/ico-map-here.png?v=1.0";
                }
            }
            else if (movingstatus === "position") {
                if (this.state.previewTypeId === "0") {
                    if (replayRowArr.isOverSpeed && this.state.overspeedChecked) {
                        iconImage = "../../dist/img/vehicle-replay/ico-map-over-speed.png?v=1.0";
                    }
                    else {
                        iconImage = this.getMarkerIconUrl(replayRowArr.status, replayRowArr.course);
                    }
                    zIndex = 200;
                }
            }
            else {
                iconImage = this.getReplayVehicleMarkerIconUrl(replayRowArr.status, replayRowArr.course);
            }
            // const infoBoxDesc =
            // "<div>" +
            // "<b>Date Time: </b>" + moment(replayRowArr.gpsDateTime).format('DD-MMM h:mm') +
            // "<br/><b>Speed: </b>" + replayRowArr.speed  +  " kmph" +
            // "<br/><b>Odometer: </b> " + replayRowArr.odometer  + 
            // "<br/><b>Location: </b> " + replayRowArr.address  + 
            // "<br/><div onClick={this.copyToClipboard}>" + replayRowArr.latitude + "," + replayRowArr.longitude  +  "</div>" +
            // "<br/><span onClick= " + this.copyToClipboard() + "> Click Me 01 </span>" +
            // "</div>";

            let infoBoxDesc = "<b>Date Time: </b>" + moment(replayRowArr.gpsDateTime).format('DD-MMM HH:mm:ss') + "<br/><b>Speed: </b>" + replayRowArr.speed + " kmph <br /><b>Odometer: </b> " + replayRowArr.odometer + " <br/><b>Location: </b> " + replayRowArr.address + "<br /><span>" + replayRowArr.latitude + ',' + replayRowArr.longitude + "</span></div>";
            let replayMarker = null;
            let replayMarker2 = null;
            let replayMarker3 = null;
          
            if (this.state.previewTypeId === "1" && (replayRowArr.stoppageTime != null && replayRowArr.stoppageTime != "")) {
                replayMarker = new MarkerWithLabel({
                    position: latLng,
                    map: this.map,
                    icon: iconImage,
                    infoBoxDesc: infoBoxDesc,
                    labelContent: replayRowArr.stoppageTime,
                    labelStyle: {
                        backgroundColor: "#ffffff",
                        border: "1px solid #cecece",
                        borderRadius: "8px",
                        padding: "2px 10px 2px 10px",
                        fontSize: "11px",
                        fontWeight: "bold",
                        fontFamily: 'Segoe UI',
                        color: "#095590",
                    },
                    labelAnchor: new window.google.maps.Point(10, 62),
                    zIndex: zIndex,
                    title: replayRowArr.status,
                });
            }
            else {
                replayMarker = new window.google.maps.Marker({
                    position: latLng,
                    map: this.map,
                    icon: iconImage,
                    title: replayRowArr.status,
                    infoBoxDesc: infoBoxDesc,
                    zIndex: zIndex
                });

                if (this.state.showEventLabel) {
                    if (replayRowArr.stoppageTime != null && replayRowArr.stoppageTime != "") {
                        replayMarker2 = new MarkerWithLabel({
                            position: latLng,
                            map: this.map,
                            icon: iconImage,
                            infoBoxDesc: infoBoxDesc,
                            labelContent: replayRowArr.stoppageTime,
                            labelStyle: {
                                backgroundColor: "#ffc473",
                                border: "1px solid #cecece",
                                borderRadius: "8px",
                                padding: "2px 10px 2px 10px",
                                fontSize: "11px",
                                fontWeight: "bold",
                                fontFamily: 'Segoe UI',
                                color: "#095590",
                            },
                            labelAnchor: new window.google.maps.Point(0, 20),
                            zIndex: zIndex,
                            title: replayRowArr.status,
                        });
                    }
                }
            }

            var jobTripEvent = replayRowArr.jobTripEvent;
            if (jobTripEvent != undefined && jobTripEvent != null && jobTripEvent.length > 0) {
                var iconImage3 = "";
                var eventArr = jobTripEvent.split(',');
                if (eventArr[0].includes("START TRIP")) {
                    iconImage3 = '../assets/img/replay/ico-map-Start_Event.png?v=1.0';
                }
                else if (eventArr[0].includes("ON SCENE")) {
                    iconImage3 = '../assets/img/replay/ico-map-Scene_Event.png?v=1.0';
                }
                else if (eventArr[0].includes("ON BOARD")) {
                    iconImage3 = '../assets/img/replay/ico-map-Board_Event.png?v=1.0';
                }
                else if (eventArr[0].includes("HOSPITAL IN")) {
                    iconImage3 = '../assets/img/replay/ico-map-Hospital_Drop_Event.png?v=1.0';
                }
                else if (eventArr[0].includes("HOSPITAL OUT")) {
                    iconImage3 = '../assets/img/replay/ico-map-Hospital_Out_Event.png?v=1.0';
                }
                else if (eventArr[0].includes("CLEAR")) {
                    iconImage3 = '../assets/img/replay/ico-map-Back_To_Base_Event.png?v=1.0';
                }
                else if (eventArr[0].includes("ON ROAD")) {
                    iconImage3 = '../assets/img/replay/ico-map-Start_Event.png?v=1.0';
                }
                else if (eventArr[0].includes("OFF ROAD")) {
                    iconImage3 = '../assets/img/replay/ico-map-Back_To_Base_Event.png?v=1.0';
                }
                var onlygpsTime = moment(replayRowArr.gpsDateTime).format("HH:mm:ss");
                var labelContent3 = "<span class='fas fa-square " + eventArr[0].replaceAll(" ", "_") + "'></span>  <span>" + jobTripEvent + " " + onlygpsTime + "</span>";
                replayMarker3 = new MarkerWithLabel({
                    position: latLng,
                    map: this.map,
                    icon: iconImage3,
                    infoBoxDesc: infoBoxDesc,
                    labelContent: labelContent3,
                    labelStyle: {
                        backgroundColor: "#ffffff",
                        border: "1px solid #cecece",
                        borderRadius: "8px",
                        padding: "2px 10px 2px 10px",
                        fontSize: "11px",
                        fontWeight: "bold",
                        fontFamily: 'Segoe UI',
                        color: "#095590",
                    },
                    labelAnchor: new window.google.maps.Point(10, 62),
                    zIndex: zIndex,
                    title: replayRowArr.status,
                });
            }

            if (movingstatus == "start" || movingstatus == "end") {
                replayMarker.setAnimation(window.google.maps.Animation.BOUNCE);
                setTimeout(function () {
                    replayMarker.setAnimation(null);
                }, 3000);
            }

            window.google.maps.event.addListener(replayMarker, 'click', (event) => {
                this.showReplayInfoBox(replayMarker);
            });

            if (movingstatus == "start" || movingstatus == "end") {
                this.startEndMarkers.push(replayMarker);
            }
            else {
                this.replayMarkers.push(replayMarker);
            }
            if (replayMarker2 != null) {
                window.google.maps.event.addListener(replayMarker2, 'click', (event) => {
                    this.showReplayInfoBox(replayMarker2);
                });
                this.replayMarkers.push(replayMarker2);
            }
            if (replayMarker3 != null) {
                window.google.maps.event.addListener(replayMarker3, 'click', (event) => {
                    this.showReplayInfoBox(replayMarker3);
                });
                this.replayMarkers.push(replayMarker3);
            }
            replayMarker.setVisible(true);
            replayMarker.setPosition(latLng);
            replayMarker.setIcon(iconImage);

            let mapBounds = this.map.getBounds();
            if (!mapBounds.contains(replayMarker.getPosition())) {
                this.map.panTo(latLng);
            }

        } catch (err) {
            console.log("Error in " + this.displayName + ".drawReplayMarker function", err);
        }
    }

    drawRoutePolyline = (fromLat, fromLon, toLat, toLon, polylineColor, speed) => {
        let polyLine = [];
        let speedwiseColorCodes = this.state.speedwiseColorCodes;
        let colorCodeValue = "red";

        this.state.speedwiseColorCodes.map(function (colorcode, i) {
            if (speed >= colorcode.minSpeed && speed <= colorcode.maxSpeed) {
                colorCodeValue = colorcode.colorName;
            }
        });

        polyLine.push(new window.google.maps.LatLng(parseFloat(fromLat), parseFloat(fromLon)));
        polyLine.push(new window.google.maps.LatLng(parseFloat(toLat), parseFloat(toLon)));

        let polyline = new window.google.maps.Polyline({
            path: polyLine,
            strokeColor: colorCodeValue,
            strokeOpacity: .725,
            strokeWeight: 4
        });

        polyline.setMap(this.map);
        this.replayRoutePolyLine.push(polyline);
    }

    playReplay = (e) => {
        e.preventDefault();
        try {

            if (this.state.trackingObj != null) {
                this.setState({ isPlay: true, isStopped: false, isPaused: false });

                let currentWayPoint = this.state.trackingObj.replayData[this.state.replayCounter];
                if (currentWayPoint != null) {
                    this.map.panTo(new window.google.maps.LatLng(currentWayPoint.latitude, currentWayPoint.longitude));
                    this.map.setZoom(15);
                }

                clearInterval(this.replayTimer);

                this.replayTimer = setInterval(() => {
                    let counter = 0;
                    this.clearReplayMarker();

                    if (this.lastAnimatedMarker != null) {
                        this.lastAnimatedMarker.setMap(null);
                    }
                    let replayItem = this.state.trackingObj.replayData[this.state.replayCounter];
                    counter = this.state.replayCounter;
                    this.lastAnimatedMarker = this.drawReplayMarker(replayItem, "");
                    this.setState({ replayCounter: counter + 1 });

                    if (this.state.replayCounter === this.state.trackingObj.replayData.length - 1) {
                        this.setState({ replayCounter: 0 });
                        clearInterval(this.replayTimer);
                    }
                }, this.state.replaySpeed);

            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".playReplay function", err);
        }
    }

    pauseReplay = (e) => {
        e.preventDefault();
        this.setState({ isPlay: false, isStopped: false, isPaused: true });
        clearInterval(this.replayTimer);
    }

    stopReplay = (e) => {
        e.preventDefault();
        this.setState({ isPlay: false, isStopped: true, isPaused: true, replayCounter: 0, previewTypeId: "1" });
        clearInterval(this.replayTimer);
        this.handleSearch();
    }

    getMarkerIconUrl = (status, course) => {

        let _course = course - (course % 5);
        if (status === "Moving") {
            if (this.state.previewTypeId == "0") {
                return "../../dist/img/device-icons/Direction/Direction_green_" + _course + ".png?v=1.0";
                //return "ClientApp\public\dist\img\avatar04.png" // + _course + ".png?v=1.0";;
            }
            else {
                //  return "ClientApp\public\dist\img\avatar04.png" // + _course + ".png?v=1.0";
                return "../../dist/img/device-icons/Car/Car_green_" + _course + ".png?v=1.0";
            }
        } else if (status === "Stopped") {
            return "../../dist/img/device-icons/Direction/Direction_red_" + _course + ".png?v=1.0";
        } else if (status === "Idle") {

            var text = "../../dist/img/device-icons/Direction/Direction_orange_" + _course + ".png?v=1.0";
            return text;
        } else {
            return "../../dist/img/device-icons/Direction/Direction_blue_" + _course + ".png?v=1.0";
        }
    }

    getReplayVehicleMarkerIconUrl = (hostStatus, course) => {
        let _course = course - (course % 5);
        let iconeName = "";
        //return "../assets/img/device-icons/Ambulance/Ambulance_blue_" + _course + ".png?v=1.0";
        let folderAndiconName = iconeName != '' ? iconeName : 'Car';
        var test = "../../dist/img/device-icons/" + folderAndiconName + "/" + folderAndiconName + "_" + this.getColorByHostStatus(hostStatus) + (
            (iconeName != "TrainEngine" && iconeName != "AcTrainEngine") ? ("_" + _course) : "") + ".png?v=1.6";

        return test;
    }
    getColorByHostStatus = (hostStatus) => {
        let color = "";
        if (hostStatus === "") {
            color = "orange";
        } else if (hostStatus === "Moving") {
            color = "green";
        } else if (hostStatus === "Stopped") {
            color = "red";
        } else if (hostStatus === "Idle") {
            color = "orange";
        } else if (hostStatus === "Out Of Service") {
            color = "blue";
        }
        return color;
    }

    getReplayIconUrl = (hostStatus, course) => {
        let _course = course - (course % 5);
        if (hostStatus === 1) {

            // "../../dist/img/device-icons/Direction/Direction_blue.png"
            return "../../dist/img/device-icons/Direction/Direction_blue_" + _course + ".png?v=1.0";
        } else if (hostStatus === 2) {
            return "../../dist/img/device-icons/Direction/Direction_blue_" + _course + ".png?v=1.0";
        } else if (hostStatus === 3) {
            return "../../dist/img/device-icons/Direction/Direction_blue_" + _course + ".png?v=1.0";
        } else if (hostStatus === 4) {
            return "../../dist/img/device-icons/Direction/Direction_blue_" + _course + ".png?v=1.0";
        } else {
            return "../../dist/img/device-icons/Direction/Direction_blue";
        }
    }

    showReplayInfoBox = (markerDescription) => {
        if (this.replayInfoWindow != null)
            this.replayInfoWindow.close();

        this.replayInfoWindow = new window.google.maps.InfoWindow({
            content: markerDescription.infoBoxDesc
        });
        this.replayInfoWindow.open(this.map, markerDescription);
    }

    convertPositionListToArray = (data) => {
        var array = [];
        for (var m of data) {
            var obj = new Object();
            obj.lat = m.latitude;
            obj.lng = m.longitude;
            array.push(obj);
        }

        return array;
    }

    componentWillUnmount = () => {
        clearInterval(this.replayTimer);
        this.setState({ isPlay: false, isStopped: true, isPaused: true })
        this.setState = (state, callback) => {
            return;
        };
    };

    closeInfo = (e) => {
        //this.refs.vehicleInfoComponent.closeInfo(e);
    }

    showInfo = async (e) => {
        e.preventDefault();
        try {
            var request = new Object();
            request.UserId = parseInt(sessionStorage.getItem('session_userId'));
            request.AccountId = parseInt(sessionStorage.getItem('session_accountId'));
            request.LocationId = parseInt(sessionStorage.getItem('session_locationId'));
            request.IndustryId = 0;
            request.VehicleId = this.state.vehicleId;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'VehicleLastStatus/GetVehicleLastStatus', requestParams);
            const allVehiclesdata = await response.json();
            const vehicledata = allVehiclesdata.payload != null ? allVehiclesdata.payload.dataValues : [];
            let data = vehicledata.filter(f => f.vehicleId === this.state.vehicleId)[0];
            //this.refs.vehicleInfoComponent.showInfo(data);

        } catch (err) {
            console.log("Error in " + this.displayName + ".showInfo function", err);
        }
    }

    locateMarker = (e, data) => {
        e.preventDefault();
        this.map.setZoom(22);
        this.map.panTo(new window.google.maps.LatLng(data.latitude, data.longitude));
    }

    setToday = async (e) => {
        e.preventDefault();
        this.setState({
            fromDatetime: moment(currentTime).startOf('day').toDate(),
            toDatetime: moment(currentTime).endOf('day').toDate()
        })
    }

    setYesterday = async (e) => {
        e.preventDefault();
        this.setState({
            fromDatetime: moment(currentTime).subtract(1, 'day').startOf('day').toDate(),
            toDatetime: moment(currentTime).subtract(1, 'day').endOf('day').toDate()
        })
    }

    exportButton = async (e) => {
        e.preventDefault();
        if (this.dataGrid != undefined && this.dataGrid.instance != undefined) {
            this.dataGrid.instance.exportToExcel();
        }
    }

    render() {
        return (
            <Fragment>
                <div className="card">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <small>Ride Id: <b> {this.state.rideId} </b></small>&nbsp;
                        <small>On Location Time: <b>{this.state.fromDatetime}</b></small>&nbsp;
                        <small>Drop off Time: <b> {this.state.toDatetime} </b></small>&nbsp;
                        {/*<small>On Location Time: <b>{moment(this.state.fromDatetime).format(DISPLAY_FORMAT)}</b></small>&nbsp;*/}
                        {/*<small>Drop off Time: <b> {moment(this.state.toDatetime).format(DISPLAY_FORMAT)} </b></small>&nbsp;*/}
                        <small>Total Distance:<b> {this.state.trackingObj == null ? 0 : Math.round(this.state.trackingObj.totalDistance, 2)} KM(s) </b></small>
                    </div>                </div>
                <div className="card vehicleReplyContainer">
                    <form method="post" style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="vehicleReplyFilter p-2">
                            <div>
                                <label className="mb-0">Preview:&nbsp;</label>
                                <select className="replay-select-box" name="previewTypeId" placeholder="Select preview type" title="Preview Type" value={this.state.previewTypeId} onChange={this.handleInputChange}>
                                    <option value={0}>Pinned</option>
                                    <option value={1}>Animated</option>
                                </select>
                            </div>
                            <div style={{ display: (this.state.displayPlayReplay === 1 ? "inline-block" : "none") }} >
                                <div style={{ display: "flex" }}>
                                    <select className="replay-select-box" name="replaySpeed" placeholder="Select speed" title="Speed Type"
                                        value={this.state.replaySpeed} onChange={this.handleSpeedChange}>
                                        <option value={1000}>Normal</option>
                                        <option value={500}>2x</option>
                                        <option value={250}>4x</option>
                                        <option value={125}>8x</option>
                                    </select>&nbsp;&nbsp;
                                    <button className="btn btn-icon btn-sm btn-light replay-animation-button" disabled={this.state.isPlay} onClick={this.playReplay} ><i className="fas fa-play"></i></button>
                                    <button className="btn btn-icon btn-sm btn-light replay-animation-button" disabled={this.state.isPaused} onClick={this.pauseReplay} ><i className="fas fa-pause"></i></button>
                                    <button className="btn btn-icon btn-sm btn-light replay-animation-button" disabled={this.state.isStopped} onClick={this.stopReplay} ><i className="fas fa-stop"></i></button>
                                </div>
                            </div>
                            <div>
                                <button type="submit" className="btn btn-sm btn-success" style={{ fontSize: 12 + 'px' }} onClick={this.handleSearch} >
                                    {this.state.isSubmited && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                    {!this.state.isSubmited && <span title="Replay"><i className="fas fa-redo"></i> Replay</span>}
                                </button>
                            </div>


                        </div>
                    </form >
                </div >
                <div className="card">
                    {/*  google map */}
                    <div ref={this.mapRef} id="map" style={{ height: `100vh`, width: `100%` }}></div>
                </div>
            </Fragment >
        );
    }
}