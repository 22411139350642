import React, { Component } from 'react';
import { BasicMapStyle } from '../../helpers/mapstylejson'
import { getCurrentLocation } from '../../helpers/globalfunctions'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow, DirectionsRenderer } from "react-google-maps" //npm install --save react-google-maps
import Swal from 'sweetalert2'; //npm install --save sweetalert2
const googleMapURL = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCe3gBSL4p1HctLMiVgGw1aYEcs2BKJYpw&v=3.exp&libraries=geometry,drawing,places";

const demoTrackingData = [
    {
        rideId: 4422521531
        , vehicleId: 1
        , vehicleNo: "MH05 EP 3700"
        , vehicleTypeId: 5
        , vehicleType: "VAN"
        , latitude: 40.731887783696465
        , longitude: - 73.86869420143684
        , gpsDateTimeStr: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
        , rideStatusId: 107
        , gpsstatus: "ON LOCATION"
        , statusId: 303
        , statusDescription: "ON JOB"
        , pickupDateTimeStr: "03/12/2021 13:26"
        , driverName: "abhishek"
        , mobileNo: "9999999999"
        , puStreet: "JFK Airport Terminal 4 NY 2001"
        , doStreet: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , puLatitude: 40.644418
        , puLongitude: - 73.789061
        , doLatitude: 40.70871557720287
        , doLongitude: - 73.99368082930151
        , speed: 20
        , pax: 2
        , paxName: "Jagdish Gosavi"
    },
    {
        rideId: 4422521532
        , vehicleId: 2
        , vehicleNo: "MH05 EP 3701"
        , vehicleTypeId: 5
        , vehicleType: "VAN"
        , latitude: 40.907610229562756
        , longitude: - 72.8262761242683
        , gpsDateTimeStr: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
        , rideStatusId: 107
        , gpsstatus: "ON LOCATION"
        , statusId: 303
        , statusDescription: "ON JOB"
        , pickupDateTimeStr: "03/12/2021 13:26"
        , driverName: "omkar"
        , mobileNo: "9999999999"
        , puStreet: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , doStreet: "JFK Airport"
        , puLatitude: 40.644418
        , puLongitude: - 73.789061
        , doLatitude: 40.70871557720287
        , doLongitude: - 73.99368082930151
        , speed: 20
        , pax: 3
        , paxName: "Mohammad Adam"
    },
    {
        rideId: 4422521533
        , vehicleId: 3
        , vehicleNo: "MH05 EP 3702"
        , vehicleTypeId: 5
        , vehicleType: "VAN"
        , latitude: 40.81193162529278
        , longitude: - 74.29548149014192
        , gpsDateTimeStr: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
        , rideStatusId: 107
        , gpsstatus: "ON LOCATION"
        , statusId: 303
        , statusDescription: "ON JOB"
        , pickupDateTimeStr: "03/12/2021 13:26"
        , driverName: "deepak"
        , mobileNo: "9999999999"
        , puStreet: "JFK Airport"
        , doStreet: "Manhantton Hotel"
        , puLatitude: 40.644418
        , puLongitude: - 73.789061
        , doLatitude: 40.70871557720287
        , doLongitude: - 73.99368082930151
        , speed: 20
        , pax: 5
        , paxName: "Sharad Shitole"
    },
]
const customCarMarker = {
    path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805",
    fillColor: "red",
    fillOpacity: 2,
    strokeWeight: 1,
    rotation: 0,
    scale: 0.6,
    anchor: new window.google.maps.Point(20, 20),
};
export class LocateVehicleComponent extends Component {

    static displayName = LocateVehicleComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            rideId: 0,
            trackingData: null,
            mapDefaultOption: {
                mapTypeControl: true,
                zoom: 8,
                disableDefaultUI: true,
                panControl: true,
                clickableIcons: false,
                zoomControl: true,
                scaleControl: true,
                streetViewControl: true,
                overviewMapControl: true,
                fullscreenControl: true,
                rotatControl: true,
                gestureHandling: "greedy",
                gestureHandling: 'cooperative'
            },
            center: { lat: 40.71783983489211, lng: - 74.0030823652621 },
            origin: null,
            destination: null,
            routeDirections: null,
            durationText: '',
            showMarkerAddressInfoWindow: false,
            vehicleInfoWindow: null,
            showVehiclesDataList: false,
        }
        this.componentMounted = false;
    }
    async componentDidMount() {
        try {
            if (this.componentMounted === false) {
                this.componentMounted = true;
                await this.getTrackingData();
            }
        } catch (error) {
            console.log(error);
        }
    }
    getTrackingData = async () => {
        try {
            this.setState((prev) => ({ ...prev, loading: true }));
            const queryParam = new URLSearchParams(window.location.search);
            const accountId = queryParam.has("accountId") ? Number(queryParam.get("accountId")) : 0;
            const locationId = queryParam.has("locationId") ? Number(queryParam.get("locationId")) : 0;
            const rideId = queryParam.has("rideId") ? Number(queryParam.get("rideId")) : 0;
            const vgTypeId = queryParam.has("vgTypeId") ? queryParam.get("vgTypeId") : '';
            const userId = queryParam.has("userId") ? Number(queryParam.get("userId")) : 0;
            const vehicleId = 0;
            const request = { AccountId: accountId, LocationId: locationId, RideId: rideId, VgTypeId: vgTypeId, LoginUserId: userId };
            const requestParams = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request)
            }
            const response = await fetch(`tracking-api/${vehicleId}/track-list`, requestParams);
            const data = await response.json();
            let trackingData = data?.trackVehicles?.length !== 0 ? data.trackVehicles : null;// demoTrackingData;
            let currentLocation = await getCurrentLocation();
            let origin = null, destination = null, vehicleInfoWindow = null, center = { lat: currentLocation.lat, lng: currentLocation.lng };//{ ...this.state.center };
            if (trackingData && trackingData.length > 0) {
                center = { lat: trackingData[0].latitude, lng: trackingData[0].longitude };
                if (trackingData.length === 1) {
                    vehicleInfoWindow = { vehicleId: trackingData[0].vehicleId };
                    origin = { lat: trackingData[0].latitude, lng: trackingData[0].longitude };
                    destination = trackingData[0].rideStatusId === 108 ? { lat: trackingData[0].doLatitude, lng: trackingData[0].doLongitude, address: trackingData[0].doStreet } : { lat: trackingData[0].puLatitude, lng: trackingData[0].puLongitude, address: trackingData[0].puStreet }
                    this.getDirections(origin, destination);
                }
            }
            this.setState((prev) => ({ ...prev, trackingData: trackingData, loading: false, rideId: rideId, origin: origin, destination: destination, center: center, vehicleInfoWindow: vehicleInfoWindow }));
            setInterval(() => { }, 1000);
            if (trackingData?.length === 0) {
                await Swal.fire({
                    title: "<small>Locate Vehicle</small>",
                    text: "Unable to find GPS location of vehicle.",
                    icon: "success",
                    width: '45%',
                    dangerMode: false,
                    allowOutsideClick: false
                });
            }
        } catch (error) {
            console.log("Error in getTrackingData function", error);
            this.setState((prev) => ({ ...prev, loading: false }));
        }

    }
    getDirections = (origin, destination) => {
        try {
            if (origin && destination) {
                const directionsService = new window.google.maps.DirectionsService();
                directionsService.route({
                    origin,
                    destination,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    durationInTraffic: true,
                }, (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        this.setState((prev) => ({
                            ...prev,
                            routeDirections: result,
                            durationText: result.routes[0].legs[0].duration_in_traffic.text,
                            center: { lat: origin.lat, lng: origin.lng },
                            mapDefaultOption: {
                                ...this.state.mapDefaultOption,
                                zoom: 8
                            }
                        }));
                    } else {
                        console.error(`error fetching directions ${result}`);
                    }
                });
            }

        } catch (error) {
            console.log("Error in getDirections function", error);
        }
    }
    handelOnRefreshMap = () => {
        window.location.reload(false);
    }
    handleOnVehicleMarkerClick = (vehicleId, vehicleLatitude, vehicleLongitude, doStreet, doLatitude, doLongitude) => {
        try {
            this.setState((prev) => ({
                ...prev,
                vehicleInfoWindow: {
                    vehicleId: vehicleId
                },
                mapDefaultOption: {
                    ...this.state.mapDefaultOption,
                    zoom: 8
                },
                center: {
                    lat: vehicleLatitude,
                    lng: vehicleLongitude,
                },
                origin: {
                    lat: vehicleLatitude,
                    lng: vehicleLongitude,
                },
                destination: {
                    lat: doLatitude,
                    lng: doLongitude,
                    address: doStreet,
                },
                routeDirections: null,
                durationText: "",
                showVehiclesDataList: false,
            }));
            this.getDirections({
                lat: vehicleLatitude,
                lng: vehicleLongitude,
            }, {
                lat: doLatitude,
                lng: doLongitude,
            })
        } catch (error) {
            console.log("Error in handleMarkerClick function", error);
        }
    }
    handleOnClearVehicleMarkerClick = () => {
        try {
            let origin = { ...this.state.origin };
            let destination = { ...this.state.destination };
            let routeDirections = { ...this.state.routeDirections };
            let durationText = { ...this.state.durationText };
            if (this.state.trackingData && this.state.trackingData.length > 1) {
                // crlear if more than one tracking data
                origin = null;
                destination = null;
                routeDirections = null;
                durationText = "";
            }
            this.setState((prev) => ({
                ...prev,
                mapDefaultOption: {
                    ...this.state.mapDefaultOption,
                    zoom: 8
                },
                vehicleInfoWindow: null,
                origin: origin,
                destination: destination,
                routeDirections: routeDirections,
                durationText: durationText,
            }));
        } catch (error) {
            console.log("Error in handleOnClearMarkerClick function", error);
        }
    }

    render() {
        const AsyncLoadMap = withScriptjs(
            withGoogleMap((props) => (
                <GoogleMap
                    defaultZoom={this.state.mapDefaultOption.zoom}
                    defaultCenter={new window.google.maps.LatLng(this.state.center)}
                    defaultOptions={this.state.mapDefaultOption, { styles: BasicMapStyle }}
                >
                    {this.state.routeDirections && <DirectionsRenderer directions={this.state.routeDirections} defaultOptions={{
                        suppressMarkers: true
                    }} />}
                    {this.state.destination &&
                        <Marker
                            position={{
                                lat: this.state.destination.lat,
                                lng: this.state.destination.lng,
                            }}
                            icon={{
                                url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                            }}
                            onClick={() => {
                                this.setState((prev) => ({ ...prev, showMarkerAddressInfoWindow: true }))
                            }}
                        >
                            {this.state.showMarkerAddressInfoWindow &&
                                <InfoWindow onCloseClick={() => {
                                    this.setState((prev) => ({ ...prev, showMarkerAddressInfoWindow: false }))
                                }} options={{ maxWidth: 100 }} >
                                    <div style={{ paddingBottom: 3 + 'px' }}>
                                        <span>{this.state.destination.address}</span>
                                    </div>
                                </InfoWindow>}
                        </Marker>
                    }

                    {this.state.trackingData && this.state.trackingData.map((vehicle, index) => (
                        <Marker
                            key={index}
                            position={{
                                lat: vehicle.latitude,
                                lng: vehicle.longitude,
                            }}
                            icon={
                                {
                                    url: "https://member.utwiz.com/TrackVehicle/Car/0.png",
                                    scaledSize: new window.google.maps.Size(30, 30),
                                    anchor: new window.google.maps.Point(20, 20),
                                    origin: new window.google.maps.Point(0, 0),
                                }
                                //customCarMarker
                            }
                            onClick={() => {
                                let infoData = {
                                    vehicleId: vehicle.vehicleId,
                                    vehicleLatitude: vehicle.latitude,
                                    vehicleLongitude: vehicle.longitude,
                                    doStreet: vehicle.rideStatusId === 108 ? vehicle.doStreet : vehicle.puStreet,
                                    doLatitude: vehicle.rideStatusId === 108 ? vehicle.doLatitude : vehicle.puLatitude,
                                    doLongitude: vehicle.rideStatusId === 108 ? vehicle.doLongitude : vehicle.puLongitude
                                }
                                this.handleOnVehicleMarkerClick(
                                    infoData.vehicleId,
                                    infoData.vehicleLatitude,
                                    infoData.vehicleLongitude,
                                    infoData.doStreet,
                                    infoData.doLatitude,
                                    infoData.doLongitude
                                );
                            }}
                        >
                            {
                                this.state.vehicleInfoWindow && this.state.vehicleInfoWindow.vehicleId === vehicle.vehicleId && (
                                    <InfoWindow onCloseClick={this.handleOnClearVehicleMarkerClick} options={{ maxWidth: 100 }} >
                                        <div style={{ paddingBottom: 3 + 'px' }}>
                                            <div style={{ paddingBottom: 8 + 'px' }}><strong className="text-muted">Ride Id: </strong>{vehicle.rideId}</div>
                                            <table className="table table-head-fixed table-hover">
                                                <tbody>
                                                    <tr>
                                                        <td><strong className="text-muted"><i className="fas fa-user"></i> Crew Name</strong><br /><label id="lblPaxName">{vehicle.paxName.replace(';', ',')}</label></td>
                                                    </tr>
                                                    <tr>
                                                        <td width='60%'><strong className="text-muted"><i className="fas fa-car"></i> Vehicle</strong><br /><label id="lblVehicle">{vehicle.vehicleNo}</label></td>
                                                        <td><strong className="text-muted"><i className="fas fa-user"></i> Driver</strong><br /><label id="lblDriver">{vehicle.driverName}</label> <label className="badge badge-success" id="lblStatus">{vehicle.statusDescription}</label></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong className="text-muted"><i className="fas fa-clock"></i> Last GPS Time</strong><br /><label id="lblGpsTime">{vehicle.gpsDateTimeStr}</label></td>
                                                        <td><strong className="text-muted"><i className="fas fa-clock"></i> Pickup Time</strong><br /><label id="lblPickupTime">{vehicle.pickupDateTimeStr}</label></td>

                                                    </tr>
                                                    <tr>
                                                        <td align="top"><strong className="text-muted"><i className="fas fa-map-marker-alt"></i> Pick-up</strong><br /><label id="lblPickup">{vehicle.puStreet}</label></td>
                                                        <td align="top"><strong className="text-muted"><i className="fas fa-map-marker-alt"></i> Dropoff</strong><br /><label id="lblDropoff">{vehicle.doStreet}</label></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong className="text-muted"><i className="fas fa-hourglass-start"></i> <span id="lblEtaEtdName">{vehicle.rideStatusId === 108 ? "ETD" : "ETA"}</span></strong><br /><span id="lblEtaEtd">{this.state.durationText}</span></td>
                                                        <td><strong className="text-muted"><i className="fas fa-tachometer-alt"></i> Speed</strong><br /><label id="lblSpeed">{vehicle.speed.toFixed(3)} MPH</label></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </InfoWindow>
                                )
                            }
                        </Marker>
                    ))};
                    <button type="button" className="map-refresh-button" onClick={this.handelOnRefreshMap}> Refresh</button>
                </GoogleMap >
            ))
        );
        return (
            <div style={{ width: "100vw", height: "100vh" }}>
                <AsyncLoadMap
                    ref={this.mapRef}
                    googleMapURL={googleMapURL}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `99%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
                {
                    this.state.trackingData && this.state.trackingData.length > 1 && (
                        <>
                            <div id="map-body">
                                <div id="map-list-data" className="card" style={{ display: this.state.showVehiclesDataList ? "block" : "none" }}>
                                    <div className="card-body table-responsive p-0" style={{ height: 250 + 'px' }}>
                                        <table className="table table-head-fixed table-hover">
                                            <thead>
                                                <tr>
                                                    <th width="10px"></th>
                                                    <th>Vehicle Number</th>
                                                    <th>Vehicle Type</th>
                                                    <th>Total Pax</th>
                                                    <th>Driver Name</th>
                                                    <th>Pickup Time</th>
                                                    <th>Last GPS Time</th>
                                                    <th>Trip Status</th>
                                                    <th>Vehicle Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.trackingData.map((vehicle, index) =>
                                                    <tr className="callout callout-success" key={index}>
                                                        <td><a style={{ cursor: "pointer" }} onClick={() => {
                                                            let infoData = {
                                                                vehicleId: vehicle.vehicleId,
                                                                vehicleLatitude: vehicle.latitude,
                                                                vehicleLongitude: vehicle.longitude,
                                                                doStreet: vehicle.rideStatusId === 108 ? vehicle.doStreet : vehicle.puStreet,
                                                                doLatitude: vehicle.rideStatusId === 108 ? vehicle.doLatitude : vehicle.puLatitude,
                                                                doLongitude: vehicle.rideStatusId === 108 ? vehicle.doLongitude : vehicle.puLongitude
                                                            }
                                                            this.handleOnVehicleMarkerClick(
                                                                infoData.vehicleId,
                                                                infoData.vehicleLatitude,
                                                                infoData.vehicleLongitude,
                                                                infoData.doStreet,
                                                                infoData.doLatitude,
                                                                infoData.doLongitude
                                                            );
                                                        }}><i className="fas fa-car text-info"></i></a></td>
                                                        <td>{vehicle.vehicleNo}</td>
                                                        <td>{vehicle.vehicleType}</td>
                                                        <td>{vehicle.pax}</td>
                                                        <td>{vehicle.driverName}</td>
                                                        <td>{vehicle.pickupDateTimeStr}</td>
                                                        <td>{vehicle.gpsDateTimeStr}</td>
                                                        <td>{vehicle.gpsstatus}</td>
                                                        <td><span className="badge bg-success">{vehicle.statusDescription}</span></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div id="bottom_bar">
                                <span id="bottom_menu">
                                    <a className="btn btn-success btn-sm btn-block" style={{ fontSize: "14px" }} onClick={() => { this.setState((prev) => ({ ...prev, showVehiclesDataList: !this.state.showVehiclesDataList })) }}>Click here to show/hide list of vehicles</a>
                                </span>
                            </div>
                        </>
                    )
                }
            </div>
        )
    }
}
