import React, { Component } from 'react';
import moment from 'moment/min/moment-with-locales';
import { getRequestParams, formatDate, formatTime, formatFullDate } from '../../helpers/globalfunctions';
import ReactDOM from 'react-dom';
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow, DirectionsRenderer } from "react-google-maps" //npm install --save react-google-maps
//https://tomchentw.github.io/react-google-maps/
import Swal from 'sweetalert2'; //npm install --save sweetalert2
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal)

export class TrackVehicleComponent extends Component {
    static displayName = TrackVehicleComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            rideId: 0,
            trackingData: [],
            trackDetails: [],
            airlineCode: '',
            airlineId: '',
            flightDateTime: '',
            airportName: '',
            airlineNo: '',
            airlineName: '',
            airlineImage: '',
            flightStatus: '',
            flightpickTime: '',
           
            errors: {
                userName: '',
            }
        }
        this.refreshMap = this.refreshMap.bind(this);
        this.multipleVehicleMap = React.createRef();
    }

    componentDidMount() {
        this.getRideTrackingDetails();
    }

    async refreshMap() {
        window.location.reload(false);
    }

    async getRideTrackingDetails() {
        try {
            const queryString = require('query-string');
            var parsed = queryString.parse(this.props.location.search);
            if (parsed) {
                var requestId = parsed.requestId;
                const requestParams = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
                const response = await fetch('tracking-api/flight-track-ride-details/' + requestId, requestParams);
                const data = await response.json();
                let trackDetails = data.trackVehicles == null ? [] : data.trackVehicles;
                this.setState({
                    trackDetails: trackDetails,
                    airlineCode: trackDetails[0].airlineCode,
                    airlineId: trackDetails[0].airlineId,
                    flightDateTime: trackDetails[0].flightDateTime,
                    airlineNo: trackDetails[0].airlineNo,
                    airportName: trackDetails[0].airportName,
                    airlineName: trackDetails[0].airlineName,
                    airlineImage: trackDetails[0].airlineImage,
                    flightStatus: trackDetails[0].flightStatus,
                    flightpickTime: trackDetails[0].puDateTime
                });

            } else {
                this.setState({
                    trackingData: []
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    openTracking = (e, rideId) => {
        e.preventDefault();
        this.props.history.push({
            pathname: '/livetracking',
            state: { sourceUrl: "/trackVehicle", rideId: rideId }
        })
    }

    render() {
        return (
            <div className="body">
                <div className="ride-date">{formatFullDate(this.state.flightpickTime, 'MMM/dd/yyyy')}</div>
                <div className="card-rez-info">
                    <table cellPadding="0" cellspacing="0" border="0" width="100%">
                        <tr>
                            <td className="icon-airline" width="50px"><img
                                src={this.state.airlineImage}
                                width="50px" height="50px" /></td>
                            <td className="info-airline" width="120px">
                                <div><strong>{this.state.airlineCode}-{this.state.airlineNo}</strong></div>
                                <div className="airline-name">{this.state.airlineCode} -{this.state.airlineName}</div>
                            </td>
                            <td className="airport-name" align="right">{this.state.airportName}</td>
                        </tr>
                    </table>
                    <hr />
                    <table cellPadding="0" cellspacing="0" border="0" width="100%">
                        <tr>
                            <td className="flight-time" width="50%"><span className="title">Flight Time</span><br /><span className="value">{formatTime(this.state.flightDateTime, 'hh:mm')}</span></td>&nbsp;&nbsp;
                            <td className="flight-status" align="right">{this.state.flightStatus}</td>
                        </tr>
                    </table>
                    <div className="info-live-status">ⓘ Check for live status 30 mins prior to pick-up time</div>

                </div>
                {
                    this.state.trackDetails &&
                    this.state.trackDetails.map((item, index) =>
                        <div className='box' key={index} id="divSchedule">
                            <div className="card-ride-list">
                                <div className="card-ride">
                                    <div className="info-pu-do">
                                        <table cellPadding="0" cellspacing="0" border="0" width="100%">
                                            <tr>
                                                <td className="time" width="40%"><span className="title">PU Time</span><br /><span className="value">{formatTime(item.puDateTime, 'hh:mm')}</span>&nbsp;
                                                    {/*<span onClick={(e) => this.openTracking(e, item.rideId)} style={{ color: "blue" }}><i className="fas fa-map-marker"></i></span>*/}
                                                </td>
                                                <td className="direction" width="10%">→</td>
                                                <td className="time" width="40%" align="right"><span className="title">EXP. DO Time</span><br /><span className="value">{item.dropOffTime !== "" ? formatTime(item.dropOffTime, 'hh:mm') : 'N/A'}</span></td>

                                            </tr>
                                        </table>
                                    </div>
                                    <hr />
                                    <div className="info-pickup">
                                        <table cellPadding="0" cellspacing="0" border="0" width="100%">
                                            <tr>
                                                <td className="ico-flight" width="40px">
                                                    {item.rideTypeId === 1 ? <i class="fas fa-plane-departure text-success"></i> : <i class="fas fa-bed text-danger"></i>}
                                                </td>
                                                <td className="info-airport-transporter-hotel">
                                                    <div className="airport">
                                                        <span className="title"></span><span className="value">{item.puLocationName}</span>
                                                        <span className="divider">•</span>
                                                        <span className="title">Terminal</span><span className="value">{item.terminal}</span>
                                                        <span className="divider">•</span>
                                                        <span className="title">Gate</span><span className="value">{item.gate}</span>
                                                    </div>
                                                    <div className="transporter">
                                                        <span className="title">Transporter</span><span className="value">{item.accountName}</span>
                                                    </div>
                                                    <div className="vehicle">
                                                        <span className="title">Vehicle Type</span><span className="value">{item.vehicleType}</span>
                                                        <span className="divider">•</span>
                                                        <span className="title">Vehicle #</span><span className="value">{item.vehicleNo}</span>
                                                    </div>

                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div align="right">
                                        <hr style={{ width: 90 + '%' }} />
                                    </div>
                                    <div className="info-dropoff">
                                        <table cellPadding="0" cellspacing="0" border="0" width="100%">
                                            <tr>
                                                <td className="ico-flight" width="40px">
                                                    {item.rideTypeId === 2 ? <i class="fas fa-plane-departure text-success"></i> : <i class="fas fa-bed text-danger"></i>}</td>
                                                <td className="info-airport-transporter-hotel">
                                                    <div className="hotel">
                                                        <span className="value">{item.doLocationName}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="btn-action"><button type="button" className="btn btn-primary" onClick={(e) => this.openTracking(e, item.rideId)}>Locate Vehicle</button></div>

                                </div>

                                {/*<div className="card-ride">*/}
                                {/*    <div className="info-pu-do">*/}
                                {/*        <table cellPadding="0" cellspacing="0" border="0" width="100%">*/}
                                {/*            <tr>*/}
                                {/*                <td className="time" width="40%"><span className="title">PU Time</span><br /><span className="value">{formatTime(item.pickupDateTime, 'hh:mm')}</span></td>*/}
                                {/*                <td className="direction" width="10%">→</td>*/}
                                {/*                <td className="time" width="40%" align="right"><span className="title">EXP. DO Time</span><br /><span className="value">{formatTime(item.dispatchedTime, 'hh:mm')}</span></td>*/}
                                {/*            </tr>*/}
                                {/*        </table>*/}
                                {/*    </div>*/}
                                {/*    <hr />*/}
                                {/*    <div className="info-pickup">*/}
                                {/*        <table cellPadding="0" cellspacing="0" border="0" width="100%">*/}
                                {/*            <tr>*/}
                                {/*                <td className="ico-flight" width="40px"><img*/}
                                {/*                    src="https://www.pngfind.com/pngs/m/144-1449288_png-file-svg-hotel-bed-icon-png-transparent.png"*/}
                                {/*                    width="25px" /></td>*/}
                                {/*                <td className="info-airport-transporter-hotel">*/}
                                {/*                    <div className="hotel">*/}
                                {/*                        <span className="value">{item.doStreet}</span>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="transporter">*/}
                                {/*                        <span className="title">Transporter</span><span className="value">SkyRide</span>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="vehicle">*/}
                                {/*                        <span className="title">Vehicle Type</span><span className="value">{item.vehicleType}</span>*/}
                                {/*                        <span className="divider">•</span>*/}
                                {/*                        <span className="title">Vehicle #</span><span className="value">{item.vehicleNo}</span>*/}
                                {/*                    </div>*/}
                                {/*                </td>*/}
                                {/*            </tr>*/}
                                {/*        </table>*/}
                                {/*    </div>*/}
                                {/*    <div align="right">*/}
                                {/*        <hr style={{ width: 90 + '%' }} />*/}
                                {/*    </div>*/}
                                {/*    <div className="info-dropoff">*/}
                                {/*        <table cellPadding="0" cellspacing="0" border="0" width="100%">*/}
                                {/*            <tr>*/}
                                {/*                <td className="ico-flight" width="40px"><img*/}
                                {/*                    src="https://beebom.com/wp-content/uploads/2015/02/airline-logos-airasia-e1424575285622.jpg?quality=75&strip=all"*/}
                                {/*                    width="25px" height="25px" /></td>*/}
                                {/*                <td className="info-airport-transporter-hotel">*/}
                                {/*                    <div className="airport">*/}
                                {/*                        <span className="title">Airport</span><span className="value">{item.puStreet}</span>*/}
                                {/*                        <span className="divider">•</span>*/}
                                {/*                        <span className="title">Terminal</span><span className="value">3</span>*/}
                                {/*                        <span className="divider">•</span>*/}
                                {/*                        <span className="title">Gate</span><span className="value">D19</span>*/}
                                {/*                    </div>*/}
                                {/*                </td>*/}
                                {/*            </tr>*/}
                                {/*        </table>*/}
                                {/*    </div>*/}

                                {/*</div>*/}
                            </div>
                        </div>
                    )}
            </div>

        );
    }
}

