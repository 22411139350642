import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { TrackingComponent } from './components/Tracking/TrackingComponent';
import { RideTrackingReplay } from './components/Tracking/RideTrackingReplay';
import { LiveTrackingComponent } from './components/Tracking/LiveTrackingComponent';
import { TrackVehicleComponent } from './components/Tracking/TrackVehicleComponent';
import { ReplayComponent } from './components/Tracking/ReplayComponent';
import { CrewManagerTrackingComponent } from './components/Tracking/CrewManagerTrackingComponent';
import { LocateVehicleComponent } from './components/Tracking/LocateVehicleComponent';
export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={TrackingComponent} />
                {/* new component replacement of TrackingComponent*/}
                {/*<Route exact path='/' component={LocateVehicleComponent} />*/}
                <Route exact path='/ride-replay' component={RideTrackingReplay} />
                <Route exact path='/livetracking' component={LiveTrackingComponent} />
                <Route exact path='/trackVehicle' component={TrackVehicleComponent} />
                <Route exact path='/replay' component={ReplayComponent} />
                <Route exact path='/crewmanagertracking' component={CrewManagerTrackingComponent} />
            </Layout>
        );
    }
}