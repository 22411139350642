import React, { Component, Fragment } from 'react';
import { getRequestParams, formatDate } from '../../helpers/globalfunctions.js';
import { BasicMapStyle } from '../../helpers/mapstylejson';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { SelectBox } from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { unique } from 'jquery';
const position = { of: '#historydiv' };

export const TripStatusTypedata = [
    //{ id: 2, tripStatusName: "All" }, //Not Assigned
    //{ id: 3, tripStatusName: "On Job" },
    { id: 1, tripStatusName: "Assigned" },
    { id: 0, tripStatusName: "Not Assigned" }, //All
]
var MarkerWithLabel = require('markerwithlabel')(window.google.maps);
export class CrewManagerTrackingComponent extends Component {
    static displayName = CrewManagerTrackingComponent.name;
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        let rideId = 0;
        let accountId = 0;
        let locationId = 0;
        if (this.props.location.search) {
            const queryString = require('query-string');
            var parsed = queryString.parse(this.props.location.search);
            if (parsed) {
                accountId = (!parsed) ? 0 : Number(parsed.accountId);
                locationId = (!parsed) ? 0 : Number(parsed.locationId);
                rideId = (!parsed) ? 0 : Number(parsed.rideId);
            }
        }
        this.state = {
            infoWindow: true,
            infoVehicle: "none",
            isSubmited: false,
            datasource: [],
            singledatasource: [],
            accountdatasource: [],
            serviceVehicles: [],
            tripStatusTypedata: TripStatusTypedata,
            tripStatusTypeId: 2,
            vehicleLastStatus: [],
            loadPanelVisible: false,
            accountId: accountId,
            locationId: locationId,
            vehicleId: 0,
            selectedvehicleId: 0,
            vehicleNo: '',
            rideId: rideId,
            vgTypeId: '',
            isAutoRefresh: false,
            center: {
                lat: 19.0936,
                lng: 72.856629,
            },
            vehicleLatitude: 0,
            vehicleLongitude: 0,
            zoom: 8,
            mapStyle: BasicMapStyle,
            markerLabelVisibility: false,
            selectedAmbulance: [],
            lastGpsTime: '',
            pickupTIme: '',
            vehicleData: [],
            seconds: 30,
            allVehicle: 0,
            assignedVehicle: 0,
            notAssignedVehicle: 0,
            onJobVehicle :0
        }
        this.infoWindow = null;
        this.vehicleMarkers = [];
        this.closeInfo = this.closeInfo.bind(this);
        this.showInfo = this.showInfo.bind(this);
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.getTracking.bind(this)
            }
        });
    }
    async componentDidMount() {        
        clearInterval(this.vehicleIntervalHandle);
        clearInterval(this.singleVehicleIntervalHandle);
        if (this.state.sourceUrl != "") {
            sessionStorage.setItem("session_PageName", "Tracking");
        }
        this.bindMap();
        // let myVar = setTimeout(() => this.getTracking(), 500);
        this.getServiceVehicles();
        this.getTracking();
        this.getTripStatusType();

        //this.vehicleIntervalHandle = setInterval(() => {
        //    this.getTracking();
        //}, this.state.seconds * 1000);
        this.setState({ loadPanelVisible: true });
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }
    getServiceVehicles = async ()=> {
        try {
            const requestParams = getRequestParams('GET', {});
            const response = await fetch('tracking-api/dropdown/' + this.state.accountId + "/" + this.state.locationId, requestParams);
            const data = await response.json();
            this.setState({
                serviceVehicles: data.vehiclesDropdown
            });
        } catch (e) {
            console.log(e);
        }
    }
    getTracking = async (e) => {
       // e.preventDefault();
        this.setState({
            loadPanelVisible: true,
        });
        try {
            var request = {};
            request.VehicleId = parseInt(this.state.vehicleId);
            request.AccountId = this.state.accountId; //isNaN(this.state.accountId) === true ? 0 : parseInt(this.state.accountId);
            request.LocationId = this.state.locationId; // isNaN(this.state.locationId) === true ? 0 : parseInt(this.state.locationId);
            request.RideId = this.state.rideId;
            request.TripStatusType = this.state.tripStatusTypeId;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('tracking-api/get-crewManager-rides', requestParams);
            const data = await response.json();

            let tempAssignedCount = 0;
            let tempNotAssignedCount = 0;
            let tempAllCount = 0;
            let tempOnJobCount = 0;
            if (data.list != null) {
                let uniqueJobs = Array.from(new Set(data.list.map((e) => e.jobId && e.isVehicleHasJob === 1)));
                //console.log("uniqueJobs: " + uniqueJobs.length);
                let tempAssignedCountList = data.list.filter(e => e.isVehicleHasJob === 1 && e.jobId === 0); // To Get count for Job id is not generated but vehicle has assigned
                //let tempAssignedCountList = data.list.filter(e => e.isVehicleHasJob === 1); // To Get count for Job id is not generated but vehicle has assigned
                //console.log("tempAssignedCountList: " + tempAssignedCountList.length);
                let tempAssignedCountList1 = data.list.filter(e => e.isVehicleHasJob === 1 && e.jobId > 0);  // To Get count for Job id is generated but vehicle has assigned
                //console.log("tempAssignedCountList1: " + tempAssignedCountList1.length);
                let tempAssignedCountList2 = Array.from(new Set(tempAssignedCountList1.map((e) => e.jobId))); // To removed duplicate job 
                //console.log("tempAssignedCountList2: " + tempAssignedCountList2.length);
                tempAssignedCount = parseInt(tempAssignedCountList.length) + parseInt(tempAssignedCountList2.length);
                //tempAssignedCount = parseInt(tempAssignedCountList.length);
                //console.log("tempAssignedCount: " + tempAssignedCount);
                tempNotAssignedCount = data.list.filter(e => e.isVehicleHasJob === 0 && e.jobId === 0).length;
                //console.log("tempNotAssignedCount: " + tempNotAssignedCount);
                tempOnJobCount = uniqueJobs;
                tempAllCount = tempAssignedCount + tempNotAssignedCount;
            }

            //const uniqueJobs = Array.from(new Set(data.list.map((e) => e.jobId && e.isVehicleHasJob === 1)));
            //tempAssignedCount = data.list.filter(e => e.isVehicleHasJob === 1).length; //uniqueJobs.filter(e => e > 0).length;
            //tempNotAssignedCount = data.list.filter(e => e.isVehicleHasJob === 0).length;
            //tempAllCount = tempAssignedCount + tempNotAssignedCount;
            //tempOnJobCount = uniqueJobs;
            
            this.setState({
                datasource: data.list != null ? data.list : [],
                singledatasource: (this.state.selectedvehicleId > 0 && data.list != null) ? data.list.filter(e => e.vehicleId === this.state.selectedvehicleId) : data.list,
                loadPanelVisible: false,
                vehicleNo: data.list != null ? data.list[0].vehicleNo : "",
                vehicleLatitude: data.list != null ? data.list[0].latitude : 0,
                vehicleLongitude: data.list != null ? data.list[0].longitude : 0,
                allVehicle: tempAllCount,
                notAssignedVehicle: tempNotAssignedCount,
                assignedVehicle: tempAssignedCount,
                onJobVehicle: tempOnJobCount
            });

            this.bindVehicleTrackingData(data.list);
            this.setState({
                loadPanelVisible: false,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getTracking function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }

    getVehicleTracking = async (e) => {
        // e.preventDefault();
        this.setState({
            loadPanelVisible: true,
        });
        try {
            var request = {};
            request.VehicleId = parseInt(this.state.vehicleId);
            request.AccountId = this.state.accountId; //isNaN(this.state.accountId) === true ? 0 : parseInt(this.state.accountId);
            request.LocationId = this.state.locationId; // isNaN(this.state.locationId) === true ? 0 : parseInt(this.state.locationId);
            request.RideId = this.state.rideId;
            request.TripStatusType = this.state.tripStatusTypeId;
            
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('tracking-api/get-crewManager-rides', requestParams);
            const data = await response.json();

            let tempAssignedCount = 0;
            let tempNotAssignedCount = 0;
            let tempAllCount = 0;
            let tempOnJobCount = 0;
            let uniqueJobs = [];

            if (data.list != null) {
                uniqueJobs = Array.from(new Set(data.list.map((e) => e.jobId && e.isVehicleHasJob === 1)));
                //tempAssignedCount = data.list.filter(e => e.isVehicleHasJob === 1).length//uniqueJobs.filter(e => e > 0).length;
                //tempNotAssignedCount = data.list.filter(e => e.isVehicleHasJob === 0).length;
                //tempAllCount = tempAssignedCount + tempNotAssignedCount;
                //tempOnJobCount = uniqueJobs;

                uniqueJobs = Array.from(new Set(data.list.map((e) => e.jobId && e.isVehicleHasJob === 1)));
                let tempAssignedCountList = data.list.filter(e => e.isVehicleHasJob === 1 && e.jobId === 0); // To Get count for Job id is not generated but vehicle has assigned
                //let tempAssignedCountList = data.list.filter(e => e.isVehicleHasJob === 1); // To Get count for Job id is not generated but vehicle has assigned
                let tempAssignedCountList1 = data.list.filter(e => e.isVehicleHasJob === 1 && e.jobId > 0);  // To Get count for Job id is generated but vehicle has assigned
                let tempAssignedCountList2 = Array.from(new Set(tempAssignedCountList1.map((e) => e.jobId))); // To removed duplicate job 
                tempAssignedCount = parseInt(tempAssignedCountList.length) + parseInt(tempAssignedCountList2.length);
                //tempAssignedCount = parseInt(tempAssignedCountList.length);
                tempNotAssignedCount = data.list.filter(e => e.isVehicleHasJob === 0 && e.jobId === 0).length;
                tempOnJobCount = uniqueJobs;
                tempAllCount = tempAssignedCount + tempNotAssignedCount;
            }

            if (this.state.tripStatusTypeId === 2) {  // ALL
                this.setState({
                    datasource: data.list != null ? data.list : [],
                    singledatasource: (this.state.selectedvehicleId > 0 && data.list != null) ? data.list.filter(e => e.vehicleId === this.state.selectedvehicleId) : data.list,
                    loadPanelVisible: false,
                    vehicleNo: data.list != null ? data.list[0].vehicleNo : "",
                    vehicleLatitude: data.list != null ? data.list[0].latitude : 0,
                    vehicleLongitude: data.list != null ? data.list[0].longitude : 0,
                    allVehicle: tempAllCount,
                });
            }
            else if (this.state.tripStatusTypeId === 1) { // ASSIGEND
                let tempAssignedCountList = data.list.filter(e => e.isVehicleHasJob === 1 && e.jobId === 0); // To Get count for Job id is not generated but vehicle has assigned
                let tempAssignedCountList1 = data.list.filter(e => e.isVehicleHasJob === 1 && e.jobId > 0);
                let tempAssignedCountList2 = Array.from(new Set(tempAssignedCountList1.map((e) => e.jobId)));
                tempAssignedCount = parseInt(tempAssignedCountList.length) + parseInt(tempAssignedCountList2.length);                
                this.setState({
                    datasource: data.list != null ? data.list : [],
                    singledatasource: (this.state.selectedvehicleId > 0 && data.list != null) ? data.list.filter(e => e.vehicleId === this.state.selectedvehicleId) : data.list,
                    loadPanelVisible: false,
                    vehicleNo: data.list != null ? data.list[0].vehicleNo : "",
                    vehicleLatitude: data.list != null ? data.list[0].latitude : 0,
                    vehicleLongitude: data.list != null ? data.list[0].longitude : 0,
                    assignedVehicle: tempAssignedCount,
                });
            }
            else if (this.state.tripStatusTypeId === 0) { // NOT ASSIGEND
                this.setState({
                    datasource: data.list != null ? data.list : [],
                    singledatasource: (this.state.selectedvehicleId > 0 && data.list != null) ? data.list.filter(e => e.vehicleId === this.state.selectedvehicleId) : data.list,
                    loadPanelVisible: false,
                    vehicleNo: data.list != null ? data.list[0].vehicleNo : "",
                    vehicleLatitude: data.list != null ? data.list[0].latitude : 0,
                    vehicleLongitude: data.list != null ? data.list[0].longitude : 0,
                    notAssignedVehicle: tempNotAssignedCount,
                });
            }
            else {
                this.setState({
                    datasource: data.list != null ? data.list : [],
                    singledatasource: (this.state.selectedvehicleId > 0 && data.list != null) ? data.list.filter(e => e.vehicleId === this.state.selectedvehicleId) : data.list,
                    loadPanelVisible: false,
                    vehicleNo: data.list != null ? data.list[0].vehicleNo : "",
                    vehicleLatitude: data.list != null ? data.list[0].latitude : 0,
                    vehicleLongitude: data.list != null ? data.list[0].longitude : 0,
                    onJobVehicle: tempOnJobCount,
                });
            }
            this.bindVehicleTrackingData(data.list);
            this.setState({
                loadPanelVisible: false,
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getTracking function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }
    getTripStatusType = async () => {
        this.setState({
            loadPanelVisible: true,
        });
        try {
            this.setState({ tripStatusTypedata: TripStatusTypedata })
        } catch (err) {
            console.log("Error in " + this.displayName + ".getTracking function", err);
            this.setState({
                loadPanelVisible: false
            });
        }
    }
   
    bindMap() {
        let mapStyle = BasicMapStyle;
        this.map = new window.google.maps.Map(this.mapRef.current, {
            center: (this.state.vehicleData != null && this.state.vehicleData.length > 0) ? new window.google.maps.LatLng(this.state.vehicleData[0].latitude, this.state.vehicleData[0].longitude) : this.state.center,
            zoom: (this.state.vehicleData != null && this.state.vehicleData.length > 0) ? this.state.zoom : 8,
            styles: mapStyle
        });
    }
    bindVehicleTrackingData(data) {
        try {
            this.setState({
                vehicleLastStatus: data != null ? data : [],
            }, () => {
                this.drawVehicleMarkers();
                this.onVehicleFilterChanged();
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".bindVehicleTrackingData function", err);
        }
    }
    removeMarkers = (map) => {
        for (let i = 0; i < this.vehicleMarkers.length; i++) {
            const marker = this.vehicleMarkers[i];
            marker.setMap(null);
        }
    }
    getMarkerIconUrl = (course, jobId, isVehicleHasJob) => {
        var icon = "../../dist/img/device-icons/car/car_blue.png?v=1.6";
        if (course != undefined) {
            let _course = course - (course % 5);
            let folderAndiconName = 'Car';
            icon = "../../dist/img/device-icons/" + folderAndiconName + "/" + folderAndiconName + "_" + this.getColorByHostStatus(jobId, isVehicleHasJob) +
                ("_" + _course) + ".png?v=1.6";
        }
        return icon;
    }
    getColorByHostStatus = (jobId, isVehicleHasJob) => {
        let color = "";
        //if (hostStatus === 0) {
        //    color = "red";
        //} else if (hostStatus === 1) {
        //    color = "green";
        //} else if (hostStatus === 2) {
        //    color = "red";
        //} else if (hostStatus === 3) {
        //    color = "red";
        //} else if (hostStatus === 4) {
        //    color = "blue";
        //}
        //if (jobId > 0 && isVehicleHasJob === 1) {
        //    color = "green";
        //}
        //else if (jobId === 0 && isVehicleHasJob === 0) {
        //    color = "blue"
        //}
        //else {
        //    color = "red";
        //}

        if (isVehicleHasJob === 1) {
            color = "green";
        }
        else if (isVehicleHasJob === 0) {
            color = "red";
        }

        return color;
    }
    drawVehicleMarkers = () => {
        try {
            //this.removeMarkers();
            this.vehicleMarkers = [];
            let bounds = new window.google.maps.LatLngBounds();
            var vehicles = this.state.singledatasource.length > 0 ? this.state.singledatasource : this.state.datasource;
            for (let i = 0; i < vehicles.length; i++) {
                const vehicle = vehicles[i];
                let markerTitle = vehicle.vehicleNo;
                let latLng = new window.google.maps.LatLng(vehicle.latitude, vehicle.longitude);
                let iconImage = '';
                if (vehicle.jobId === 0 && vehicle.isVehicleHasJob === 1) {
                    iconImage = {
                        url: this.getMarkerIconUrl(vehicle.course, vehicle.jobId, vehicle.isVehicleHasJob),//"../../dist/img/device-icons/Car/Car_green.png?v=1.0",
                        size: new window.google.maps.Size(30, 30),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(15, 15)
                    };
                }
                else if (vehicle.jobId === 0 && vehicle.isVehicleHasJob === 0) {
                    iconImage = {
                        url: this.getMarkerIconUrl(vehicle.course, vehicle.jobId, vehicle.isVehicleHasJob), //"../../dist/img/device-icons/Car/Car_red.png?v=1.0",
                        size: new window.google.maps.Size(30, 30),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(15, 15)
                    };
                }
                else {
                    iconImage = {
                        url: this.getMarkerIconUrl(vehicle.course, vehicle.jobId, vehicle.isVehicleHasJob), //"../../dist/img/device-icons/Car/Car_red.png?v=1.0",
                        size: new window.google.maps.Size(30, 30),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(15, 15)
                    };
                }
                
                let marker = new window.google.maps.Marker({
                    vehicleId: vehicle.vehicleId,
                    position: latLng,
                    map: this.map,
                    title: markerTitle,
                    icon: iconImage,
                    showInfoWindow: this.showInfo
                });

                const contentString = '<div>'
                    + 'Vehicle No: ' + vehicle.vehicleNo + '<br />'
                    + 'Driver Name: ' + vehicle.driverName + '<br />'
                    + 'Mobile Number : ' + vehicle.mobileNo + '<br />'
                    + 'Total Pax: ' + vehicle.paxName + '<br />'
                    + 'Job No: ' + vehicle.rideId + '<br />'
                    + 'Pickup Time: ' + formatDate(vehicle.pickupDateTime, 'MM/dd/yyyy hh:mm:ss') + '<br />'
                    + 'Last GPS Time: ' + formatDate(vehicle.gpsDateTime, 'MM/dd/yyyy hh:mm:ss') + '<br />'
                    + 'Trip Status: ' + vehicle.statusDescription + '<br />'
                    //+ 'Vehicle Status: ' + vehicle.gpsStatus + '<br />'
                    + '</div>'

                var infowindow = new window.google.maps.InfoWindow();

                window.google.maps.event.addListener(marker, 'click', function () {
                    var marker = this;
                    infowindow.setContent(contentString);
                    infowindow.open(this.map, marker);
                    this.showInfoWindow(marker.vehicleId);
                });

                this.vehicleMarkers.push(marker);
                this.map.setZoom(5);
                bounds.extend(latLng);
                iconImage = '';
            }

            if (!this.state.isAutoRefresh) {
                if (!bounds.isEmpty()) {
                    this.map.fitBounds(bounds);
                }
            }
            this.setState({ isAutoRefresh: false, vehicleData: null });
        } catch (err) {
            console.log("Error in " + this.displayName + ".drawVehicleMarkers function", err);
        }
    }

    showInfo(vehicleId) {
        try {
            let vehicle = this.state.datasource != null ? this.state.datasource.filter((m) => m.vehicleId === vehicleId)[0] : null;
            //console.log(vehicle.vehicleId + "," + vehicle.deviceId);
            //this.refs.vehicleInfoComponent.showInfo(vehicle);
            this.setState({
                zoom: this.map.getZoom() < 14 ? 14 : this.map.getZoom(),
                center: {
                    lat: vehicle.latitude,
                    lng: vehicle.longitude
                },
            }, () => {
                this.map.setZoom(this.state.zoom);
                this.map.setCenter(this.state.center);
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".showInfo function", err);
        }
    }

    onVehicleFilterChanged = () => {
        let bounds = new window.google.maps.LatLngBounds();
        if (this.state.selectedvehicleId > 0) {
            let marker = this.vehicleMarkers.filter((m) => m.vehicleId == this.state.selectedvehicleId)[0];
            let currentZoom = this.map.getZoom();
            if (currentZoom != 16) {
                this.map.setZoom(16);
            }

            if (marker != undefined) {
                this.map.setCenter(marker.getPosition());
            }
        } else {
            this.setState({ selectedvehicleId: 0 });
        }
    }
    setCurrentVehicle = (e, item) => {
        e.preventDefault();
        this.setState(
            { selectedvehicleId: item.vehicleId }
        );
        //clearInterval(this.vehicleIntervalHandle);
        let myVar = setTimeout(() => this.getTracking(), 500);
        this.vehicleIntervalHandle = setInterval(() => {
            console.log("singleVehicleIntervalHandle");
            //this.getTracking();
            this.onVehicleFilterChanged();
        }, this.state.seconds * 1000);
    }


    closeInfo = async (e) => {
        this.setState({
            airportId: 0,
            airportCode: "",
            accountId: 0,
            datasource: [],
            singledatasource: [],
            infoWindow: true,
            infoVehicle: "none",
            vehicleId: 0,
            selectedvehicleId: 0,
            accountName: '',
            driverName: '',
            driverMob: '',
            status: '',
            lastGpsTime: '',
            rideId: 0,
            pickUpTime: '',
            speed: 0,
            provider: '',
            puAddress: '',
            doAddress: '',
            eta: '',
            tripStatusTypeId: 2
        });
        setTimeout(() => { this.removeMarkers(); }, 500);
        setTimeout(() => { this.getTracking(); }, 1000);
    }

    handleVehicleChange = async (e) => {
        if (e.selectedItem != null) {
            this.setState({
                vehicleId: e.selectedItem.id,
                vehicleNo: e.selectedItem.name,
                selectedvehicleId: e.selectedItem.id,
                //center: {
                //    lat: e.selectedItem.latitude,
                //    lng: e.selectedItem.longitude,
                //},
                //zoom: 8,
            });
        }
        else {
            this.setState({
                vehicleId: 0,
                vehicleNo: "",
                //center: {
                //    lat: 20.5937,
                //    lng: 78.9629,
                //},
                //zoom: 8,
            });
        }
        //setTimeout(() => { this.bindMap(); }, 300);
    }
    handleTripStatusChange = async (e) => {
        if (e.selectedItem != null) {
            this.setState({
                tripStatusTypeId: e.selectedItem.id
            });
        }
        else {
            this.setState({
                tripStatusTypeId: 2,
            });
        }
       }

    getVehicleData = ( e,trackingId) => {
        e.preventDefault();
        this.setState({ tripStatusTypeId: trackingId }, () => this.getVehicleTracking());        
    }

    render() {
        return (
            <Fragment>
                <div className="card">
                    <div className="row" >
                        <div className="col-3">
                            <div className="tripStatus">
                                <div className="form-group">
                                    <div className="fleet-status-count text-center">
                                        <label className="m-0">All</label>
                                        <h3 className="fw-bolder m-0 "><a href='#' className='text-default fw-bolder' onClick={(e) => { this.getVehicleData(e, 2) }}>{this.state.allVehicle}</a></h3>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="fleet-status-count text-center">

                                        <label className="m-0">Assigned</label>
                                        <h3 className="fw-bolder m-0 "><a href='#' className='text-success fw-bolder' onClick={(e) => { this.getVehicleData(e, 1) }}>{this.state.assignedVehicle}</a></h3>

                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="fleet-status-count text-center">
                                        <label className="m-0">Not Assigned</label>
                                        <h3 className="fw-bolder m-0 "><a href='#' className='text-danger fw-bolder' onClick={(e) => { this.getVehicleData(e, 0) }}>{this.state.notAssignedVehicle}</a></h3>
                                    </div>
                                </div>
                                {/*<div className="form-group">*/}
                                {/*    <div className="fleet-status-count text-center">*/}
                                {/*        <label className="m-0">On job</label>*/}
                                {/*        <h3 className="fw-bolder m-0 "><a href='#' className='text-success fw-bolder' onClick={(e) => { this.getVehicleData(e, 3) }}>{this.state.onJobVehicle}</a></h3>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>

                        </div>
                        <div className="col-6">
                            <div className="row">
                                <div className="col-lg-4 col-md-2 col-sm-2 col-xs-2 displayNone">
                                    <div className="form-group">
                                        <label> Trip Status:</label>
                                        <SelectBox
                                            dataSource={this.state.tripStatusTypedata}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="All Vehicle"
                                            name="tripStatusName"
                                            defaultValue={0}
                                            value={this.state.tripStatusTypeId}
                                            displayExpr='tripStatusName'
                                            //displayExpr={this.displayExpr}
                                            valueExpr='id'
                                            onSelectionChanged={this.handleTripStatusChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-2 col-sm-2 col-xs-2">
                                    <div className="form-group">
                                        <label> Vehicle:</label>
                                        <SelectBox
                                            dataSource={this.state.serviceVehicles}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="All Vehicle"
                                            name="name"
                                            defaultValue={0}
                                            value={this.state.vehicleId}
                                            displayExpr='name'
                                            //displayExpr={this.displayExpr}
                                            valueExpr='id'
                                            onSelectionChanged={this.handleVehicleChange}
                                            showClearButton={true}
                                            disabled={false}
                                            searchEnabled={true}
                                        />
                                    </div>
                                </div>
                          
                                <div className="col-lg-4 col-md-2 col-sm-2 col-xs-2" style={{ marginTop: '23px' }}>
                                    <div className="form-group" >
                                        <button type="submit" className="btn btn-success btn-sm" onClick={this.getTracking}>
                                            {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmitted && <span title="Search"><i className="fas fa-search"></i> Submit </span>}
                                        </button>&nbsp;&nbsp;
                                        <button type="submit" className="btn btn-custom-gray btn-sm" onClick={this.closeInfo}>
                                            {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isSubmitted && <span title="Search"> Clear </span>}
                                        </button>
                                    </div>
                              
                            </div>
                        </div>
                            
                        </div>
                    
                       
                     
                        
                    </div>
                </div>
                <div className='card'>
                    <div ref={this.mapRef} id="map" style={{ height: `90vh`, width: `99%` }}></div>
                </div>
               
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </Fragment>
        )
    }
}