import React, { Component } from 'react';
import { Fragment } from 'react';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <Fragment>
                {this.props.children}
            </Fragment>
        );
    }
}