import React, { Component } from 'react';
import { compose, withProps, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow, DirectionsRenderer } from "react-google-maps" //npm install --save react-google-maps
import Swal from 'sweetalert2'; //npm install --save sweetalert2
import Geocode from "react-geocode"; //npm install--save react - geocode

//const googleMapURL = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBjoimOQOcqD_5K2JwOR-3d3WlDguKmxmM&v=3.exp&libraries=geometry,drawing,places";
const googleMapURL = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCe3gBSL4p1HctLMiVgGw1aYEcs2BKJYpw&v=3.exp&callback=Function.prototype&libraries=geometry,drawing,places";
const loadingElement = <div style={{ height: `100%` }} />;
const containerElement = <div style={{ height: `99%` }} />;
const mapElement = <div style={{ height: `100%` }} />;
const googleMapsApiKey = "AIzaSyCAiQ31UBwphPPI36Ty_nzZ27QxNx34SXQ";

export const rideTrackingData = [

    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3701"
        , vehicleTypeId: 5
        , latitude: 19.48438
        , longitude: 72.87445
        , deviceTime: new Date()
        , rideStatusId: 104
        , tripStatus: "Driver Accepted"
        , locationName: "ON JOB"
        , puAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , doAddress: "JFK Airport"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3701"
        , vehicleTypeId: 5
        , latitude: 19.51399
        , longitude: 72.91699
        , deviceTime: new Date()
        , rideStatusId: 104
        , tripStatus: "Driver Accepted"
        , locationName: "ON JOB"
        , puAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , doAddress: "JFK Airport"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3701"
        , vehicleTypeId: 5
        , latitude: 19.65307
        , longitude: 72.9068
        , deviceTime: new Date()
        , rideStatusId: 104
        , tripStatus: "Driver Accepted"
        , locationName: "ON JOB"
        , puAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , doAddress: "JFK Airport"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3701"
        , vehicleTypeId: 5
        , latitude: 19.71394
        , longitude: 72.93317
        , deviceTime: new Date()
        , rideStatusId: 104
        , tripStatus: "Driver Accepted"
        , locationName: "ON JOB"
        , puAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , doAddress: "JFK Airport"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3701"
        , vehicleTypeId: 5
        , latitude: 19.84688
        , longitude: 72.94853
        , deviceTime: new Date()
        , rideStatusId: 104
        , tripStatus: "Driver Accepted"
        , locationName: "ON JOB"
        , puAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , doAddress: "JFK Airport"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3701"
        , vehicleTypeId: 5
        , latitude: 19.98264
        , longitude: 72.92805
        , deviceTime: new Date()
        , rideStatusId: 104
        , tripStatus: "Driver Accepted"
        , locationName: "ON JOB"
        , puAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , doAddress: "JFK Airport"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3701"
        , vehicleTypeId: 5
        , latitude: 19.94164
        , longitude: 72.96918
        , deviceTime: new Date()
        , rideStatusId: 104
        , tripStatus: "Driver Accepted"
        , locationName: "ON JOB"
        , puAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , doAddress: "JFK Airport"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3700"
        , latitude: 20.00249
        , longitude: 72.92902
        , deviceTime: new Date()
        , rideStatusId: 107
        , tripStatus: "ON LOCATION"
        , locationName: "ON JOB"
        , puAddress: "JFK Airport Terminal 4 NY 2001"
        , doAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3700"
        , latitude: 20.06117
        , longitude: 72.91636
        , deviceTime: new Date()
        , rideStatusId: 107
        , tripStatus: "ON LOCATION"
        , locationName: "ON JOB"
        , puAddress: "JFK Airport Terminal 4 NY 2001"
        , doAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3700"
        , latitude: 20.10841
        , longitude: 72.91629
        , deviceTime: new Date()
        , rideStatusId: 107
        , tripStatus: "ON LOCATION"
        , locationName: "ON JOB"
        , puAddress: "JFK Airport Terminal 4 NY 2001"
        , doAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3700"
        , latitude: 20.14469
        , longitude: 72.91653
        , deviceTime: new Date()
        , rideStatusId: 107
        , tripStatus: "ON LOCATION"
        , locationName: "ON JOB"
        , puAddress: "JFK Airport Terminal 4 NY 2001"
        , doAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3700"
        , latitude: 20.1709
        , longitude: 72.90217
        , deviceTime: new Date()
        , rideStatusId: 108
        , tripStatus: "Borded"
        , locationName: "ON JOB"
        , puAddress: "JFK Airport Terminal 4 NY 2001"
        , doAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3700"
        , latitude: 20.19502
        , longitude: 72.88977
        , deviceTime: new Date()
        , rideStatusId: 108
        , tripStatus: "Borded"
        , locationName: "ON JOB"
        , puAddress: "JFK Airport Terminal 4 NY 2001"
        , doAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3700"
        , latitude: 20.21015
        , longitude: 72.88602
        , deviceTime: new Date()
        , rideStatusId: 108
        , tripStatus: "Borded"
        , locationName: "ON JOB"
        , puAddress: "JFK Airport Terminal 4 NY 2001"
        , doAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3700"
        , latitude: 21.29064
        , longitude: 72.95167
        , deviceTime: new Date()
        , rideStatusId: 108
        , tripStatus: "Borded"
        , locationName: "ON JOB"
        , puAddress: "JFK Airport Terminal 4 NY 2001"
        , doAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3700"
        , latitude: 21.32146
        , longitude: 72.96385
        , deviceTime: new Date()
        , rideStatusId: 108
        , tripStatus: "Borded"
        , locationName: "ON JOB"
        , puAddress: "JFK Airport Terminal 4 NY 2001"
        , doAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3700"
        , latitude: 21.43378
        , longitude: 72.96901
        , deviceTime: new Date()
        , rideStatusId: 108
        , tripStatus: "Borded"
        , locationName: "ON JOB"
        , puAddress: "JFK Airport Terminal 4 NY 2001"
        , doAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3700"
        , latitude: 21.99395
        , longitude: 73.10729
        , deviceTime: new Date()
        , rideStatusId: 108
        , tripStatus: "Borded"
        , locationName: "ON JOB"
        , puAddress: "JFK Airport Terminal 4 NY 2001"
        , doAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3700"
        , latitude: 22.22264
        , longitude: 73.18694
        , deviceTime: new Date()
        , rideStatusId: 108
        , tripStatus: "Borded"
        , locationName: "ON JOB"
        , puAddress: "JFK Airport Terminal 4 NY 2001"
        , doAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3700"
        , latitude: 22.25131
        , longitude: 73.22032
        , deviceTime: new Date()
        , rideStatusId: 108
        , tripStatus: "Borded"
        , locationName: "ON JOB"
        , puAddress: "JFK Airport Terminal 4 NY 2001"
        , doAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3700"
        , latitude: 22.5274
        , longitude: 73.01168
        , deviceTime: new Date()
        , rideStatusId: 108
        , tripStatus: "Borded"
        , locationName: "ON JOB"
        , puAddress: "JFK Airport Terminal 4 NY 2001"
        , doAddress: "Manhantton Hotel, Hotel bridge, NY new york 1822"
        , speed: 20
    },
    {
        rideId: 4422521535
        , vehicleNo: "MH05 EP 3702"
        , latitude: 22.55147
        , longitude: 72.97059
        , deviceTime: new Date()
        , rideStatusId: 116
        , tripStatus: "Completed"
        , puAddress: "JFK Airport"
        , doAddress: "Manhantton Hotel"
        , speed: 20
    },

]
export class RideTrackingReplay extends Component {
    static displayName = RideTrackingReplay.name;
    constructor(props) {
        super(props);
        let rideId = 0;
        if (this.props.location.search) {
            const queryString = require('query-string');
            var parsed = queryString.parse(this.props.location.search);
            if (parsed) {
                rideId = (!parsed) ? 0 : Number(parsed.rideId);
            }
        }
        this.state = {
            loadPanelVisible: false,
            rideId: rideId,
            gpsLocationList: []
        }
    }
    async componentDidMount() {
        await this.getGpsLocationList();
        await this.setLocationName();
    }
    getGpsLocationList = async () => {
        try {
            if (isNaN(this.state.rideId) === true || Number(this.state.rideId) === 0) {
                await this.showMessage('error', 'Invalid request', null);
            }
            else {
                this.setState({ loadPanelVisible: true });
                const requestParams = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
                //this.setState({ gpsLocationList: rideTrackingData });
                let url = 'tracking-api/ride/' + this.state.rideId + '/replay';
                const response = await fetch(url, requestParams);
                const data = await response.json();
                if (data.isSuccess) {
                    this.setState({
                        gpsLocationList: data.gpsLocations
                    });
                }
                else {
                    await this.showMessage('info', 'Not found', 'Unable to find GPS location of ride');
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }

    setLocationName = async () => {
        Geocode.setApiKey(googleMapsApiKey);
        Geocode.setLanguage("en");
        Geocode.setRegion("es");
        Geocode.setLocationType("ROOFTOP");
        Geocode.enableDebug();
        for (let i = 0; i < this.state.gpsLocationList.length; i++) {
            Geocode.fromLatLng(this.state.gpsLocationList[i].latitude, this.state.gpsLocationList[i].longitude).then(
                (response) => {
                    this.state.gpsLocationList[i].locationName = response.results[0].formatted_address;
                },
                (error) => {
                    this.state.gpsLocationList[i].locationName = '';
                }
            );
        }
    }
    handleOnRefreshMap = () => {
        window.location.reload(false);
    }
    showMessage = async (icon, title, resultMessage) => {
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    render() {
        return (
            <div className="map-container">
                {this.state.gpsLocationList.length === 0 && <DefaultMap handleOnRefreshMap={this.handleOnRefreshMap} />}
                {this.state.gpsLocationList.length > 0 && <MultiLocationMap gpsData={this.state.gpsLocationList} handleOnRefreshMap={this.handleOnRefreshMap} />}
            </div>
        );
    }
}
const DefaultMap = compose(
    withProps({
        googleMapURL: googleMapURL,
        loadingElement: loadingElement,
        containerElement: containerElement,
        mapElement: mapElement,
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
    })
)(props =>
    <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: 18.935688743669296, lng: 72.8385286981148 }}
        defaultOptions={{
            mapTypeControl: true,
            streetViewControl: true,
            zoomControl: true,
            fullscreenControl: false,
            minZoom: 2,
            maxZoom: 18,
            gestureHandling: "greedy",
            disableDefaultUI: true
        }}
    >
        {props.isMarkerShown && <Marker position={{ lat: 18.935688743669296, lng: 72.8385286981148 }} />}
        <div><button type="button" className="map-refresh-button" onClick={props.handleOnRefreshMap}> Refresh</button></div>
    </GoogleMap>
);
const MultiLocationMap = compose(
    withProps({
        googleMapURL: googleMapURL,
        loadingElement: loadingElement,
        containerElement: containerElement,
        mapElement: mapElement,
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
        componentDidMount() {
            this.loadDetails(this.props.gpsData)
        },
        loadDetails(data) {
            const refs = {}
            const google = window.google;
            const DirectionsService = new google.maps.DirectionsService();

            const waypoints = data.map(p => ({
                location: { lat: p.latitude, lng: p.longitude },
                stopover: true
            }))

            const origin = waypoints.shift().location;
            const destination = waypoints.pop().location;
            DirectionsService.route({
                origin: origin,
                destination: destination,
                travelMode: google.maps.TravelMode.DRIVING,
                waypoints: waypoints
            }, (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    this.setState({
                        directions: result,
                    });
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            });


            this.setState({
                rawData: data,
                bounds: null,
                showInfoIndex: '',
                isShowlist: false,

                onMapMounted: ref => {
                    refs.map = ref;
                    const bounds = new window.google.maps.LatLngBounds();
                    data.map((item, i) =>
                        bounds.extend(new window.google.maps.LatLng(item.latitude, item.longitude))
                    )
                    if (ref != null) {
                        ref.fitBounds(bounds);
                    }
                },
                onBoundsChanged: () => {
                    this.setState({
                        bounds: refs.map.getBounds(),
                        center: refs.map.getCenter(),
                    })
                },
                showInfo: (a) => {
                    this.setState({ showInfoIndex: a })
                },
                handleFocusRide: (a) => {
                    this.setState({ showInfoIndex: a, zoomLevel: 18, center: { lat: data[a].latitude, lng: data[a].longitude } })
                },
                handleCloseCall: () => {
                    this.setState({ showInfoIndex: '' })
                },
                handleToggleButton: () => {
                    this.setState({ isShowlist: this.state.isShowlist ? false : true })
                    this.setState({ showInfoIndex: '', zoomLevel: undefined })
                    const bounds = new window.google.maps.LatLngBounds();
                    data.map((item, i) =>
                        bounds.extend(new window.google.maps.LatLng(item.latitude, item.longitude))
                    )
                    refs.map.fitBounds(bounds);
                }
            });
        }
    })
)(props =>
    <div>
        <GoogleMap
            ref={props.onMapMounted}
            defaultOptions={{
                mapTypeControl: true,
                streetViewControl: true,
                zoomControl: true,
                fullscreenControl: false,
                minZoom: 2,
                maxZoom: 18,
                gestureHandling: "greedy"
            }}
            zoom={props.zoomLevel}
            center={props.center}
            onBoundsChanged={props.onBoundsChanged}
        >
            {props.rawData != null && props.rawData.map((marker, index) => {
                const position = { lat: marker.latitude, lng: marker.longitude };
                return <Marker key={index} position={position} onClick={() => { props.showInfo(index) }}>
                    {(props.showInfoIndex !== '') && (props.showInfoIndex === index) &&
                        <InfoWindow onCloseClick={props.handleCloseCall} options={{ width: "100%" }} >
                            <div >
                                <span>{marker.rideId}</span>
                            </div>
                        </InfoWindow>}
                </Marker>;
            })}
            {props.directions && <DirectionsRenderer directions={props.directions} />}

            <div><button type="button" className="map-refresh-button" onClick={props.handleOnRefreshMap}> Refresh</button></div>
        </GoogleMap>
        <div id="map-body">
            <div id="map-list-data" className="card" style={{ display: props.isShowlist ? "block" : "none" }}>
                <div className="card-body table-responsive p-0" style={{ height: 250 + 'px' }}>
                    <table className="table table-head-fixed table-hover">
                        <thead>
                            <tr>
                                <th width="10px"></th>
                                <th>Vehicle No</th>
                                <th>Pick-up</th>
                                <th>Drop-off</th>
                                <th>Speed</th>
                                <th>Location</th>
                                <th>GPS Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.rawData != null && props.rawData.map((item, j) =>
                                <tr className="callout callout-success" key={j}>
                                    <td><a style={{ cursor: "pointer" }} onClick={() => { props.handleFocusRide(j) }}><i className="fas fa-car text-info"></i></a></td>
                                    <td>{item.vehicleNo}</td>
                                    <td>{item.puAddress}</td>
                                    <td>{item.doAddress}</td>
                                    <td>{item.speed + ' km/h'}</td>
                                    {/*<td>{getAddress(item.latitude,item.longitude)}</td>*/}
                                    <td>{item.locationName}</td>
                                    <td>{dateformat(item.deviceTime, 'mm/dd/yyyy hh:mm:ss')}</td>
                                    <td><span className="badge bg-success">{item.tripStatus}</span></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div id="bottom_bar">
            <span id="bottom_menu">
                <a className="btn btn-success btn-sm btn-block" onClick={props.handleToggleButton}>Click here to show/hide ride gps locations</a>
            </span>
        </div>
    </div>
);
export const dateformat = (dateObject, dformat) => {
    var d = new Date(dateObject);
    var day = d.getDate();
    var month = d.getMonth() + 1;
    var year = d.getFullYear();
    var hour = d.getHours();
    var minute = d.getMinutes();
    var second = d.getSeconds();

    if (day < 10) {
        day = "0" + day;
    }
    if (month < 10) {
        month = "0" + month;
    }

    if (hour < 10) {
        hour = "0" + hour;
    }
    if (minute < 10) {
        minute = "0" + minute;
    }
    if (second < 10) {
        second = "0" + second;
    }

    var date;
    if (dformat === "mm/dd/yyyy") {
        date = month + "/" + day + "/" + year;
    }
    else if (dformat === "mm/dd/yyyy hh:mm") {
        date = month + "/" + day + "/" + year + " " + hour + ":" + minute;
    }
    else if (dformat === "mm/dd/yyyy hh:mm:ss") {
        date = month + "/" + day + "/" + year + " " + hour + ":" + minute + ":" + second;
    }
    else if (dformat === "dd/mm/yyyy") {
        date = day + "/" + month + "/" + year;
    }
    else if (dformat === "dd/mm/yyyy hh:mm") {
        date = day + "/" + month + "/" + year + " " + hour + ":" + minute;
    }
    else if (dformat === "dd/mm/yyyy hh:mm:ss") {
        date = day + "/" + month + "/" + year + " " + hour + ":" + minute + ":" + second;
    }
    else {
        date = month + "/" + day + "/" + year;
    }

    return date;
}

export const getAddress = (latitude, longitude) => {
    let address = null;
    Geocode.setApiKey(googleMapsApiKey);
    Geocode.setLanguage("en");
    Geocode.setRegion("es");
    Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();
    Geocode.fromLatLng(latitude, longitude).then(
        (response) => {
            address = response.results[0].formatted_address;
        },
        (error) => {
            console.error('error in finding address: ' + error);
        }
    );
    return address;
}
